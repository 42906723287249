<template>
  <div class="container">
    <div class="ds-container">
      <div class="ds-left">
        <div class="ds-left-top">
          <!-- <el-tag type="success" style="width: 47%; height: 100%;text-align: center;display: flex;">已签到：<strong>{{ statusCount.signCount }}</strong></el-tag> -->
          <div class="ds-left-top-span1">已签到&nbsp;&nbsp;<strong>{{ statusCount.signCount }}人</strong></div>
          <div class="ds-left-top-span2">未签到&nbsp;&nbsp;<strong>{{ statusCount.notSignCount }}人</strong></div>
        </div>
        <div class="ds-left-bottom">
          <PieChart v-model="pieValue" :title="'签到率'" />
        </div>
      </div>
      <div class="ds-right">
        <LineChart :title="'最近12小时签到图'" v-model="timeCounts.values" :xAxis="timeCounts.names" :yName="'人数'" />
      </div>
    </div>
    <div class="pd-16" id="reference-insert-btn">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="queryParam.no" placeholder="学号" style="width: 100%;" @change="refresh()" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="queryParam.name" placeholder="姓名" style="width: 100%;" @change="refresh()" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="queryParam.phone" placeholder="手机号" style="width: 100%;" @change="refresh()" />
        </el-col>
        <el-col :span="4">
          <el-select 
            v-model="queryParam.signStatus"
            filterable 
            clearable
            placeholder="签到状态" 
            @keyup.native.enter="refresh"
            @change="refresh"
            style="width: 100%;"
          >
            <el-option label="未签到" :value="0" />
            <el-option label="已签到" :value="1" />
          </el-select>
        </el-col>
        <el-col :span="8" class="insert-btn-center">
          <el-tooltip :content="'导入' + templateName" placement="top">
            <a class="icon-btn" @click="toInsert">
              <i
                class="el-icon-upload2 p8"
                style="color: #409eff"
              />
            </a>
          </el-tooltip>
          <el-popconfirm
            :title="'是否确认导出班级参学率？'"
            icon="el-icon-download"
            icon-color="#409EFF"
            placement="top-end"
            @confirm="exportExcel"
          >
            <template #reference>
              <el-tooltip slot="reference" :content="'导出' + templateName" placement="top">
                <!-- <a class="icon-btn" @click="exportData"> -->
                  <i
                    
                    class="el-icon-download p8"
                    style="color: #409eff"
                  />
                <!-- </a> -->
              </el-tooltip>
            </template>
          </el-popconfirm>
        </el-col>
      </el-row>
    </div>
    <div class="pd-16" style="height: 100%; overflow: auto">
      <!-- 表格 -->
      <el-table
        border
        :data="list"
        style="width: 100%"
        :height="tableHeight"
        class="mt-16"
      >
        <el-table-column prop="no" label="学号" min-width="180" />
        <el-table-column prop="name" label="姓名" min-width="180" />
        <el-table-column prop="phone" label="手机号" min-width="180" />
        <el-table-column prop="room" label="寝室号" min-width="180" />
        <el-table-column prop="signStatus" label="签到状态" min-width="180">
          <template slot-scope="{ row }">
            <el-tag v-if="row.signStatus === 1" type="success">已签到</el-tag>
            <el-tag v-else type="warning">未签到</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="signTime" label="签到时间" min-width="180" />
        <el-table-column label="操作" min-width="100">
          <template slot-scope="{ row }">
            <el-tooltip content="修改" placement="top">
              <el-popconfirm
                :title="'是否修改当前手机号？'"
                icon="el-icon-edit-outline"
                icon-color="#409eff"
                placement="top-end"
                @confirm="toUpdate(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-edit-outline p8" style="color: #409eff" />
                </a>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip :content="row.signStatus === 0 ? '修改为已签到' : '修改为未签到'" placement="top">
              <el-popconfirm
                :title="'是否' + row.signStatus === 0 ? '修改为已签到' : '修改为未签到' + '？'"
                :icon="row.signStatus === 0 ? 'el-icon-folder-checked' : 'el-icon-folder-delete'"
                :icon-color="row.signStatus === 0 ? '#409eff' : '#f56c6c'"
                placement="top-end"
                @confirm="toUpdateSignStatus(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i :class="[ row.signStatus === 0 ? 'el-icon-folder-checked' : 'el-icon-folder-delete', 'p8' ]" :style="{ color: row.signStatus === 0 ? '#409eff' : '#f56c6c' }" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row id="reference-page" class="mt-8" style="text-align: right">
        <el-pagination
          background
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :total="tTotal"
          :page-sizes="[15, 30, 60, 90]"
          small
          layout="total, sizes, prev, pager, next, jumper"
          class="fr"
          @size-change="
            (size) => {
              page.pageSize = size;
            }
          "
          @current-change="
            (num) => {
              page.pageNum = num;
            }
          "
        />
      </el-row>
    </div>
    <!-- 信息导入弹出框 -->
    <FileUpload v-model="importDialog" :title="'导入' + templateName + '信息'" uploadPath="/student/sign/import" @refresh="refresh" />
    <!-- 信息编辑弹出框 -->
    <el-dialog
      :visible.sync="editDialog"
      width="500px"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeDialog"
    >
      <span slot="title"> 编辑{{ templateName }}信息 </span>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="ruleForm.phone" maxlength="11" show-word-limit />
        </el-form-item>
        <el-form-item>
          <center>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              >保 存</el-button
            >
          </center>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { exportFile } from '@/api/shottr/base'
import { updatePhone, signTimeAnalysis, queryStatusCount, updateSignStatus } from '@/api/shottr/student-sign'
import { page } from '@/api/shottr/base'
import ReferenceMatch from './components/ReferenceMatch'
import FileUpload from './components/FileUpload'
import LineChart from './components/LineChart'
import PieChart from './components/PieChart'
export default {
  components: {
    PieChart,
    LineChart,
    FileUpload,
    ReferenceMatch
  },
  data() {
    return {
      queryParam: {
        no: '',
        name: '',
        phone: '',
        signStatus: null
      },
      templateName: '学生签到名单',
      prefix: '/student/sign/',
      list: [],
      page: {
        pageNum: 1,
        pageSize: 15
      },
      tTotal: 0,
      importDialog: false,
      editDialog: false,
      ruleForm: {
        id: null,
        phone: ''
      },
      rules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' }
        ]
      },
      tableHeight: 600,
      timeCounts: {
        names: [],
        values: []
      },
      statusCount: {
        'signCount': 0,
        'notSignCount': 0
      },
      pieValue: [0, 0]
    }
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.queryData()
      }
    }
  },
  created() {
    this.initTimeCountList()
    this.initStatusCount()
    this.$nextTick(() => {
      // this.resetHeight()
    })
  },
  mounted() {
    window.onresize = () => {
      // this.resetHeight()
    }
  },
  methods: {
    toUpdateSignStatus(row) {
      var updateRow = JSON.parse(JSON.stringify(row))
      updateRow.signStatus = updateRow.signStatus === 0 ? 1 : 0;
      updateSignStatus(updateRow).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('修改成功')
          setTimeout(() => {
            this.initTimeCountList()
            this.initStatusCount()
            this.refresh()
          }, 100)
        } else {
          this.$message.error('查询失败, ' + message)
        }
      })
    },
    initTimeCountList() {
      this.timeCounts = {
        names: [],
        values: []
      }
      signTimeAnalysis().then(({ code, data, message }) => {
        if (code === 200) {
          data.forEach(val => {
            this.timeCounts.names.push(val.signTime + '点')
            this.timeCounts.values.push(val.personCount)
          })
        } else {
          this.$message.error('查询失败, ' + message)
        }
      })
    },
    initStatusCount() {
      this.statusCount = {
        'signCount': 0,
        'notSignCount': 0
      }
      this.pieValue = [0, 0]
      queryStatusCount().then(({ code, data, message }) => {
        if (code === 200) {
          this.statusCount = data
          const sumCount = data.signCount + data.notSignCount
          if (sumCount > 0) {
            const pie = (data.signCount / sumCount).toFixed(2)
            this.pieValue = [pie, pie]
          }
        } else {
          this.$message.error('查询失败, ' + message)
        }
      })
    },
    resetHeight() {
      const pageHeight = document.getElementById('reference-page').clientHeight
      const menuHeight = document.getElementById("menuId").clientHeight
      const insertBtnHeight = document.getElementById('reference-insert-btn').clientHeight
      this.tableHeight = window.innerHeight - menuHeight - insertBtnHeight - pageHeight - 100
    },
    refresh() {
      if (this.page.pageNum !== 1) {
        this.page.pageNum = 1
      } else {
        this.queryData()
      }
    },
    queryData() {
      const params = {
        ... this.page,
        ... this.queryParam
      }
      page(this.prefix, params).then(({ code, data, total }) => {
        this.tTotal = 0
        this.list = []
        if (code === 200 && data) {
          this.tTotal = total
          this.list = data
        }
      })
    },
    toInsert() {
      this.importDialog = true
    },
    toUpdate(row) {
      const rowCopy = JSON.parse(JSON.stringify(row))
      this.ruleForm = {
        id: rowCopy.id,
        phone: rowCopy.phone
      }
      this.editDialog = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePhone(this.ruleForm).then(({ code, message }) => {
            if (code === 200) {
              this.$message.success('修改成功')
              this.closeDialog()
              this.refresh()
            } else {
              this.$message.error('修改失败, ' + message)
            }
          })
        }
      })
    },
    closeDialog() {
      this.ruleForm = {
        id: null,
        phone: ''
      }
      this.$refs['ruleForm'].resetFields()
      this.editDialog = false
    },
    // remove(row) {
    //   remove(this.prefix, row.referenceId).then(({ code, message }) => {
    //     if (code === 200) {
    //       this.$message.success('删除成功')
    //       this.refresh()
    //     } else {
    //       this.$message.error('删除失败,' + message)
    //     }
    //   })
    // },
    exportExcel() {
      exportFile('/student/sign/export', 'POST', this.queryParam)
    }
  }
}
</script>

<style scoped>
.ds-left-top-span1 {
  width: 46%; 
  border-radius: 16px;
  height: 90%;
  background-color: #67c23a;
  border-color: #e1f3d8;
  color: #f0f9eb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
}
.ds-left-top-span2 {
  width: 46%; 
  border-radius: 16px;
  height: 90%;
  background-color: #e6a23c;
  border-color: #faecd8;
  color: #fdf6ec;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
}
.ds-container {
  width: 100%;
  height: 600px;
  display: flex;
  margin-bottom: 16px;
}
.ds-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.ds-left-top {
  flex: 1;
  display: flex;
  justify-content: space-between;
  background: white;
  align-items: flex-end;
  padding-left: 16px;
}
.ds-left-bottom {
  flex: 3;
  background: white;
}
.ds-right {
  flex: 2;
}
.container {
  width: 100%;
  overflow: hidden;
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
