<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <SchoolYearSemesterSelect @schoolYearChange="schoolYearChange" @semesterChange="semesterChange" />
      </el-col>
      <el-col :span="4">
        <el-button icon="el-icon-search" @click="search">查询</el-button>
        <el-popconfirm
          :title="'是否确认导出总参学率？'"
          icon="el-icon-download"
          icon-color="#409EFF"
          placement="top-end"
          @confirm="exportExcel"
        >
          <el-button slot="reference" icon="el-icon-download" style="margin-left: 8px;">导出</el-button>
        </el-popconfirm>
      </el-col>
    </el-row>
    <el-row>
      <div style="width: 100%; height: 500px;">
        <LineChart v-if="dataList && dataList.length > 0" v-model="dataList" :title="'总参学率'" :y-name="'参学率'" :x-axis="nameList"  />
        <el-empty v-else description="暂无数据~"></el-empty>
      </div>
    </el-row>
  </div>
</template>

<script>
import SchoolYearSemesterSelect from './components/SchoolYearSemesterSelect.vue'
import LineChart from '../components/LineChart'
import { all } from '@/api/shottr/participation-rate'
import { exportFile } from '@/api/shottr/base'
export default {
  components: {
    LineChart,
    SchoolYearSemesterSelect
  },
  data() {
    return {
      params: {
        schoolYear: '',
        semester: ''
      },
      nameList: [],
      dataList: []
    }
  },
  methods: {
    schoolYearChange(value) {
      this.params.schoolYear = value
    },
    semesterChange(value) {
      this.params.semester = value
    },
    validate() {
      if (!this.params.schoolYear) {
        this.$message.warning('学年查询条件不能为空！')
        return false
      }
      if (!this.params.semester) {
        this.$message.warning('学期查询条件不能为空！')
        return false
      }
      return true
    },
    search() {
      if (!this.validate()) {
        return
      }
      const params = {
        schoolYear: this.params.schoolYear,
        semester: this.params.semester
      }
      all(params).then(({ code, data, message }) => {
        this.nameList = []
        this.dataList = []
        if (code === 200) {
          if (data) {
            data.forEach(val => {
              this.nameList.push(val.phase)
              this.dataList.push(val.rate)
            })
          }
        } else {
          this.$message.error('系统异常: ' + message)
        }
      })
    },
    exportExcel() {
      if (!this.validate()) {
        return
      }
      const pData = {
        schoolYear: this.params.schoolYear,
        semester: this.params.semester
      }
      exportFile('/participation/rate/all/export', 'POST', pData)
    }
  }
}
</script>

<style scoped>

</style>