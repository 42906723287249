import request from '@/utils/request'

const prefix = '/activity'

/**
 * 活动配置
 */
export function activityConfig(data) {
  return request({
    url: prefix + '/config',
    method: 'POST',
    data
  })
}

/**
 * 活动考核
 */
export function activityCheck(data) {
  return request({
    url: prefix + '/check',
    method: 'POST',
    data
  })
}

/**
 * 未考核活动列表
 */
export function notCheckActivityList() {
  return request({
    url: prefix + '/not/check/list',
    method: 'GET'
  })
}

/**
 * 通讯稿评分
 */
export function activityNewsletterScore(data) {
  return request({
    url: prefix + '/newsletter/score',
    method: 'POST',
    data
  })
}
