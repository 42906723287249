import request from '@/utils/request'

/**
 * 查询年级集合
 * @returns 年级集合
 */
export function gradeList() {
  return request({
    url: '/student/grade/list',
    method: 'GET'
  })
}

/**
 * 根据年级获取班级
 * @param {params} params 
 * @returns 班级集合
 */
export function classList(params) {
  return request({
    url: '/student/class/list',
    method: 'GET',
    params
  })
}

/**
 * 获得学生下拉框集合
 * @returns 学生下拉框集合
 */
 export function studentList() {
  return request({
    url: '/student/list',
    method: 'GET'
  })
}

/**
 * 获得除去研究生之外所有的年级班级
 */
export function gradeClassList() {
  return request({
    url: '/student/grade/class/list',
    method: 'GET'
  })
}
