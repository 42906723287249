/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
 export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * 获得数字数组里面最大的值
 * @param { 数字数组 } arr
 */
 export function getArraysMaxNumber(arr) {
  if (arr) {
    var MAX = arr[0]
    arr.forEach(val => {
      if (val > MAX) {
        MAX = val
      }
    })
    return MAX
  } else {
    return 0
  }
}

/**
 * base64转成file对象
 * @param { base64 } dataurl
 * @param { 文件名 } filename
 */
export function base64toFile(dataurl, filename = 'file') {
  var arr = dataurl.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]
  var suffix = mime.split('/')[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
}
