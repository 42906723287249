<template>
  <el-row :gutter="20">
    <el-col :span="8">
      <el-select 
        v-model="schoolYear"
        filterable
        clearable
        placeholder="学年" 
        style="width: 100%"
        @change="schoolYearChange"
      >
        <el-option
          v-for="item in schoolYearList"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="8">
      <el-select 
        v-model="semester"
        filterable 
        clearable
        placeholder="学期" 
        style="width: 100%"
        @change="semesterChange"
      >
        <el-option
          v-for="item in semesterList"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="8">
      <el-select 
        v-model="phaseId"
        filterable 
        clearable
        placeholder="期" 
        style="width: 100%"
        @change="phaseChange"
      >
        <el-option
          v-for="item in phaseList"
          :key="item.id"
          :label="item.phase"
          :value="item.id">
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import { schoolYearList, semesterList, phaseList } from '@/api/shottr/phase'
export default {
  data() {
    return {
      schoolYearList: [],
      semesterList: [],
      phaseList: [],
      schoolYear: '',
      semester: '',
      phaseId: ''
    }
  },
  created() {
    this.initSchoolYearList()
  },
  methods: {
    initSchoolYearList() {
      schoolYearList().then(({ code, data, message }) => {
        this.schoolYearList = []
        if (code === 200) {
          this.schoolYearList = data || []
        } else {
          this.$message.error('系统异常: ' + message)
        }
      })
    },
    schoolYearChange() {
      // 清空学期数据
      this.semesterList = []
      this.semester = ''
      // 如果不是清空则重新查询
      if (this.schoolYear) {
        const params = {
          schoolYear: this.schoolYear
        }
        semesterList(params).then(({ code, data, message }) => {
          if (code === 200) {
            this.semesterList = data || []
          } else {
            this.$message.error('系统异常: ' + message)
          }
        })
      }
    },
    semesterChange() {
      // 清空期数据
      this.phaseList = []
      this.phaseId = ''
      this.phaseChange()
      // 如果不是清空则重新查询
      if (this.semester) {
        const params = {
          schoolYear: this.schoolYear,
          semester: this.semester
        }
        phaseList(params).then(({ code, data, message }) => {
          if (code === 200) {
            this.phaseList = data || []
          } else {
            this.$message.error('系统异常: ' + message)
          }
        })
      }
    },
    phaseChange() {
      this.$emit('phaseChange', this.phaseId)
    }
  }
}
</script>

<style scoped>

</style>
