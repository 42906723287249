<template>
  <div class="admin-parent-div">
    <h1 style="color: black;">欢迎{{ userInfo.name }}</h1>
  </div>
</template>

<script>
import { info, updatePwd, updateStatus } from '@/api/users'
import { notCheckActivityList } from '@/api/shottr/activity'
export default {
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    info().then(({ data }) => {
      this.userInfo = data
      setTimeout(() => {
        if (this.userInfo.status === 1) {
          const result = {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            inputPlaceholder: '密码'
          }
          this.$prompt('初次登录需要重置密码，请输入你的密码', '提示', result).then(({ value }) => {
            const params = {
              phone: this.userInfo.phone,
              password: value
            }
            updatePwd(params).then(({ code }) => {
              if(code === 200){
                this.$message({
                  type:'success',
                  message: '修改成功，请重新登录'
                })
                updateStatus(this.userInfo.phone)
                setTimeout(() => {
                  localStorage.removeItem('access_token')
                  localStorage.removeItem('userPhone')
                  localStorage.removeItem('userName')
                  localStorage.removeItem('roleMenu')
                  this.$router.push('/login')
                }, 100);
              } else {
                this.$message({
                  type:'warning',
                  message: '修改密码异常, 请稍后重试'
                })
              }
            })
          }).catch(() => {
            this.$message({
              type:'warning',
              message: '不重置密码无法登入系统！'
            })
            localStorage.removeItem('access_token')
            localStorage.removeItem('userPhone')
            localStorage.removeItem('userName')
            localStorage.removeItem('roleMenu')
            this.$router.push('/login')
          })
        }
        this.showNotCheckActivityList()
      }, 200)
    })
  },
  methods: {
    showNotCheckActivityList() {
      notCheckActivityList().then(({ data }) => {
        console.log("data", data)
        if (data) {
          data.forEach(val => {
            setTimeout(() => {
              this.$notify.info({
                title: '处理提醒',
                message: '您有一条名为【' + val.activityName + '】的团日活动待考核'
              })
            }, 500);
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.admin-parent-div {
  width: 100%;
  height: calc(100vh - 115px);
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
