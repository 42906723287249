<template>
    <div class="pd-16" style="height: 100%; overflow: auto">
      <!-- 表格 -->
      <el-table
        border
        :data="list"
        style="width: 100%"
        height="calc(100vh - 532px)"
        class="mt-16"
      >
        <el-table-column prop="stuName" label="导入学生名称" min-width="180" />
        <el-table-column prop="className" label="导入班级名称" min-width="180" />
        <el-table-column prop="studentListJson" label="分配" min-width="180">
          <template slot-scope="{ row }">
            <el-select 
              v-model="row.matchStudentId"
              filterable 
              placeholder="请选择" 
            >
              <el-option
                v-for="item in JSON.parse(row.studentListJson)"
                :key="item.id"
                :label="item.className"
                :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="{ row }">
            <el-tooltip content="分配" placement="top">
              <el-popconfirm
                :title="'是否确认分配当前信息？'"
                icon="el-icon-folder-checked"
                icon-color="#409EFF"
                placement="top-end"
                @confirm="submitForm(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-folder-checked p8" style="color: #409eff" />
                </a>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <el-popconfirm
                :title="'是否删除当前信息？'"
                icon="el-icon-delete"
                icon-color="#F56C6C"
                placement="top-end"
                @confirm="remove(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-delete p8" style="color: #f56c6c" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="mt-8" style="text-align: right">
        <el-pagination
          background
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :total="tTotal"
          :page-sizes="[15, 30, 60, 90]"
          small
          layout="total, sizes, prev, pager, next, jumper"
          class="fr"
          @size-change="
            (size) => {
              page.pageSize = size;
            }
          "
          @current-change="
            (num) => {
              page.pageNum = num;
            }
          "
        />
      </el-row>
    </div>
</template>

<script>
import { page, edit, remove } from '@/api/shottr/base'
export default {
  data() {
    return {
      prefix: '/reference/match/record/',
      list: [],
      page: {
        pageNum: 1,
        pageSize: 15
      },
      tTotal: 0,
      editDialog: false
    }
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.queryData()
      }
    }
  },
  methods: {
    refresh() {
      if (this.page.pageNum !== 1) {
        this.page.pageNum = 1
      } else {
        this.queryData()
      }
    },
    queryData() {
      page(this.prefix, this.page).then(({ code, data, total }) => {
        this.tTotal = 0
        this.list = []
        if (code === 200 && data) {
          this.tTotal = total
          data.forEach(val => {
            val.matchStudentId = ''
          })
          this.list = data
        }
      })
    },
    submitForm(row) {
      if (!row.matchStudentId) {
        this.$message.warning('请选择班级后再分配！')
        return
      }
      const pData = {
        id: row.id,
        phaseId: row.phaseId,
        studentId: row.matchStudentId
      }
      edit(this.prefix, pData).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('保存成功')
          this.refresh()
        } else {
          this.$message.error('保存失败, ' + message)
        }
      })
    },
    remove(row) {
      remove(this.prefix, row.id).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('删除成功')
          this.refresh()
        } else {
          this.$message.error('删除失败,' + message)
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: calc(100vh - 115px);
  overflow: hidden;
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
