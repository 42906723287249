import request from '@/utils/request'

const prefix = '/student/sign'

/**
 * 修改手机号
 * @param { 手机号 } data 
 * @returns request
 */
export function updatePhone(data) {
  return request({
    url: prefix + '/update/phone',
    method: 'POST',
    data
  })
}

export function signTimeAnalysis() {
  return request({
    url: prefix + '/time/analysis',
    method: 'GET'
  })
}

export function queryStatusCount() {
  return request({
    url: prefix + '/status/count',
    method: 'GET'
  })
}

export function updateSignStatus(data) {
  return request({
    url: prefix + '/update/sign/status',
    method: 'POST',
    data
  })
}
