<template>
  <div style="height: 100%; width: 100%;">
    <ChartPanel :option="option" />
  </div>
</template>

<script>
import ChartPanel from '../ChartPanel'
import { option } from './pie'
import deepcopy from 'deepcopy'
export default {
  components: {
    ChartPanel
  },

  props: {
    // title
    title: {
      type: String,
      default() {
        return ''
      }
    },
    // series data
    value: {
      type: Array,
      default() {
        return [0, 0]
      }
    }
  },

  computed: {
    option() {
      const res = deepcopy(option)
      res.title.text = this.title
      res.series[0].data = this.value
      return res
    }
  }
}
</script>