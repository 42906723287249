<template>
  <el-dialog
    :visible.sync="visible"
    :before-close="close"
    :close-on-click-modal="false"
    width="560px"
    class="upload-dialog"
  >
    <span slot="title">
      <slot name="title">
        <i :class="icon" />
        {{ title }}
      </slot>
    </span>
    <div v-loading="uploading" class="dialog-content">
      <el-upload
        ref="uploadCmpt"
        drag
        action="-"
        accept=".xlsx,.xls"
        multiple
        :http-request="uploadFile"
        :before-upload="beforeUpload"
        class="upload-container"
      >
        <div class="upload-content">
          <i class="el-icon-upload" />
          <p class="el-upload__text">将Excel文件拖到此处，或<em>点击上传</em></p>
        </div>
      </el-upload>
      <!-- <div class="mt-8 text-right">
        <a class="template-download" @click="download()"><i class="el-icon-download mr-4" />模板下载</a>
      </div> -->
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: '导入'
    },
    icon: {
      type: String,
      default: 'el-icon-upload2'
    },
    uploadPath: {
      type: String,
      default() { return '' }
    }
  },
  data() {
    return {
      visible: this.value,
      uploading: false
    }
  },
  watch: {
    value(val) {
      this.visible = val
    },
    visible(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    close() {
      this.$refs.uploadCmpt.clearFiles()
      this.visible = false
    },
    uploadFile(file) {
      this.uploading = true
      var formData = new FormData()
      formData.append('file', file.file)
      this.axios.post(this.uploadPath, formData).then(({ data }) => {
        const code = data.code
        const message = data.message
        if (code === 200) {
          this.$message.success('导入成功！')
        } else {
          this.$message.error('导入失败,' + message)
        }
      }).catch(() => {
        this.$message.error('导入失败, 有用户名已存在或有空数据')
      }).finally(() => {
        this.$emit('refresh')
        this.close()
        this.uploading = false
      })
      return false
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 20
      if (isLt1M) {
        return true
      }
      this.$message.warning('请上传不超过20M的文件.')
      return false
    }
  }
}
</script>
