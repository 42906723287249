<template>
  <el-row>
    <el-row>
      <center>
        <h3>
          {{ activity.schoolYear }}{{ activity.semester }}{{ activity.mon  }}“{{ activity.activityName }}”检查表
        </h3>
      </center>
    </el-row>
    <el-descriptions class="margin-top" :column="3" size="small">
      <el-descriptions-item>
        <template slot="label">
          <strong>检查班级</strong>
        </template>
        {{ activity.className }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <strong>实际开展时间</strong>
        </template>
        <el-date-picker
          v-model="activity.actualTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          align="right"
          :picker-options="pickerOptions"
        />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <strong>实际开展地点</strong>
        </template>
        <el-input v-model="activity.actualAddress" type="textarea" :rows="2" maxlength="32" show-word-limit />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <strong>应到人数</strong>
        </template>
        <el-input-number v-model="activity.scheduledNumber" :min="1" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <strong>实到人数</strong>
        </template>
        <el-input-number v-model="activity.actualNumber" :min="1" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <strong>团日活动负责人</strong>
        </template>
        <el-input v-model="activity.chargeUser" maxlength="8" show-word-limit />
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions class="margin-top" title="带边框列表" :column="1" size="small" border>
      <el-descriptions-item>
        <template slot="label">
          <strong>标准</strong>
        </template>
        <strong>分数</strong>
      </el-descriptions-item>
      <el-descriptions-item v-for="item in scoreDetail" :key="item.name">
        <template slot="label">
          <strong>{{ item.name }}</strong>
        </template>
        <el-input-number v-model="item.score" :min="0" />
      </el-descriptions-item>
    </el-descriptions>
    <center style="margin-top: 8px;">
      <el-button type="primary" plain style="margin-right: 8px;" @click="checkActivity">考核</el-button>
      <el-button plain @click="back">返回</el-button>
    </center>
  </el-row>
</template>

<script>
import { activityCheck } from '@/api/shottr/activity'
export default {
  props: {
    value: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      activity: {},
      scoreDetail: [
        {
          name: '主题鲜明，切合实际，符合社会主义核心价值观',
          score: 0
        },
        {
          name: '黑板上有主题，并为主体讲解做好充分的准备',
          score: 0
        },
        {
          name: '参与人员对主题有清晰的认识',
          score: 0
        },
        {
          name: '团员到齐，无旷到，无缺勤，无迟到早退现象',
          score: 0
        },
        {
          name: '到场人员积极参与，踊跃发言',
          score: 0
        },
        {
          name: '主持人注意与台下成员互动，能调动参与成员激情',
          score: 0
        },
        {
          name: '团日活动氛围浓',
          score: 0
        },
        {
          name: '台下成员无低头看手机的',
          score: 0
        },
        {
          name: '是否请到其他参与人员（老师，领导其他院副部职位以上的学生干部）',
          score: 0
        },
      ],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      }
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.activity = this.value
        if (this.value && this.value.scoreDetailJson) {
          this.scoreDetail = JSON.parse(this.activity.scoreDetailJson)
        }
      }
    }
  },
  // mounted() {
  //   this.activity = this.value
  //   if (this.value && this.value.scoreDetailJson) {
  //     this.scoreDetail = JSON.parse(this.activity.scoreDetailJson)
  //   }
  // },
  methods: {
    checkActivity() {
      if (!(this.validateRowParam(this.activity.actualTime, '实际开展时间不能为空') 
          && this.validateRowParam(this.activity.actualAddress, '实际开展地点不能为空')
          && this.validateRowParam(this.activity.scheduledNumber, '应到人数不能为空')
          && this.validateRowParam(this.activity.actualNumber, '实到人数不能为空')
          && this.validateRowParam(this.activity.chargeUser, '团日活动负责人不能为空')))
      {
        return
      }
      const pData = {
        id: this.activity.id,
        actualTime: this.activity.actualTime,
        actualAddress: this.activity.actualAddress,
        scheduledNumber: this.activity.scheduledNumber,
        actualNumber: this.activity.actualNumber,
        chargeUser: this.activity.chargeUser,
        scoreDetailJson: JSON.stringify(this.scoreDetail),
        score: this.getSumScore()
      }
      activityCheck(pData).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('考核成功')
          this.$emit('close')
        } else {
          this.$message.error('设置失败, ' + message)
        }
      })
    },
    back() {
      this.$emit('close')
    },
    validateRowParam(val, message) {
      if (!val) {
        this.$message.error(message)
        return false
      }
      return true
    },
    getSumScore() {
      var sum = 0
      this.scoreDetail.forEach(val => {
        sum += val.score
      })
      return sum
    }
  }
}
</script>

<style>

</style>