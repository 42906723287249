import request from '@/utils/request'

const baseUrl = '/participation/rate/'

/**
 * 总参学率
 * @param {params} params 
 * @returns 总参学率
 */
export function all(params) {
  return request({
    url: baseUrl + 'all',
    method: 'GET',
    params
  })
}

/**
 * 年级参学率
 * @param {data} data 
 * @returns 年级参学率
 */
 export function grade(data) {
  return request({
    url: baseUrl + 'grade',
    method: 'POST',
    data
  })
}

/**
 * 班级参学率
 * @param {params} params 
 * @returns 班级参学率
 */
 export function clazz(params) {
  return request({
    url: baseUrl + 'class',
    method: 'GET',
    params
  })
}

/**
 * 个人参学率
 * @param {data} data 
 * @returns 个人参学率
 */
 export function personal(data) {
  return request({
    url: baseUrl + 'personal',
    method: 'POST',
    data
  })
}
