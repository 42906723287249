<template>
  <el-row>
    <el-col :span="24">
      <el-select 
        v-model="studentId"
        filterable
        clearable
        multiple
        collapse-tags
        placeholder="学生" 
        style="width: 100%"
        @change="studentChange"
      >
        <el-option
          v-for="item in studentList"
          :key="item.id"
          :label="item.student"
          :value="item.id">
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import { studentList } from '@/api/shottr/student'
export default {
  data() {
    return {
      studentList: [],
      studentId: []
    }
  },
  created() {
    this.initStudentList()
  },
  methods: {
    initStudentList() {
      studentList().then(({ code, data, message }) => {
        this.studentList = []
        if (code === 200) {
          this.studentList = data || []
        } else {
          this.$message.error('系统异常: ' + message)
        }
      })
    },
    studentChange() {
      this.$emit('studentChange', this.studentId)
    }
  }
}
</script>

<style scoped>

</style>
