<template>
  <el-row>
    <el-col :span="24">
      <el-select 
        v-model="phone"
        filterable
        clearable
        collapse-tags
        placeholder="用户" 
        style="width: 100%"
        @change="userChange"
      >
        <el-option
          v-for="item in userList"
          :key="item.phone"
          :label="item.dept + '-' + item.name"
          :value="item.phone">
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import { queryAll } from '@/api/users'
export default {
  props: {
    value: {
      type: String,
      default() { return '' }
    }
  },
  data() {
    return {
      userList: [],
      phone: ''
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.phone = val
      }
    }
  },
  created() {
    this.initUserList()
  },
  methods: {
    initUserList() {
      queryAll().then(({ code, data, message }) => {
        this.userList = []
        if (code === 200) {
          this.userList = data || []
        } else {
          this.$message.error('系统异常: ' + message)
        }
      })
    },
    userChange() {
      this.$emit('userChange', this.phone)
    },
    clearValue() {
      this.phone = ''
    }
  }
}
</script>

<style scoped>

</style>
