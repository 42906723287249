import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/api/users/login',
        method: 'POST',
        data
    })
}

export function info() {
    return request({
        url: '/api/users/info',
        method: 'GET'
    })
}

export function queryAll() {
    return request({
        url: '/api/users/queryAll',
        method: 'GET'
    })
}

export function updatePwd(params) {
    return request({
        url: '/api/users/updatePassword',
        method: 'GET',
        params
    })
}

export function updateStatus(phone) {
    return request({
        url: '/api/users/update/status/' + phone,
        method: 'POST'
    })
}
