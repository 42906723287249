import axios from 'axios'
import $router from '@/router'
import { Message, MessageBox } from 'element-ui'

// create an axios instance
const service = axios.create({
  // 开发环境
  // baseURL: 'http://127.0.0.1:1020/', // url = base url + request url
  // 生产环境
  baseURL: 'https://www.tanhuasu.club/shottr/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // 如果当前用户登录就设置用户名称到请求头里去
    if (localStorage.getItem('access_token')) {
      config.headers['access_token'] = localStorage.getItem('access_token')
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.config.responseType === 'blob') {
      return response
    } else {
      const { data, status, message } = response
      // if (status === 200 && data.code === 200) {
      //   return data
      // } else {
      //   Message.error(message)
      // }
      return data
    }
  },
  error => {
    console.log( error.response)
    console.log("error")
    const { code, status, data } = error.response
    if (error.response) {
      if (code === 400 || status === 401 || status === 403) {
        if (localStorage.getItem('access_token')) {
          localStorage.clear()
          MessageBox.alert('登录失效, 请重新登录! ', '登录失败', {
            confirmButtonText: '确认',
            type: 'warning'
          }).then(() => {
            // location.reload()
            localStorage.clear() //清除缓存
            $router.push({path: '/login'}) //返回登录页
          })
        }
        return Promise.reject(error)
      }
    }
    Message({
      message: '网络连接失败, 请检查网络或联系管理员! ' || data.message,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
