import request from '@/utils/request'

/**
 * 保存参数
 * @param {data} data 
 * @returns success or fail
 */
export function edit(basePrefix, data) {
  return request({
    url: basePrefix + 'edit',
    method: 'post',
    data
  })
}

/**
 * 根据ID删除
 * @param {id} id 
 * @returns success or fail
 */
export function remove(basePrefix, id) {
  return request({
    url: basePrefix + 'remove/' + id,
    method: 'post'
  })
}

/**
 * 分页查询
 * @param {params} params 
 * @returns page datas 
 */
export function page(basePrefix, params) {
  return request({
    url: basePrefix + 'page',
    method: 'get',
    params
  })
}

/**
 * 根据ID获得详情
 * @param {id} id 
 * @returns detail
 */
export function detail(basePrefix, id) {
  return request({
    url: basePrefix + 'detail/' + id,
    method: 'get'
  })
}

/**
 * 导出文件
 * @param {url} url 
 * @param {method} method 
 * @param {data} data 
 */
export function exportFile(url, method, data) {
  request({
    url, method, data, responseType: 'blob'
  }).then(res => {
    const a = document.createElement('a')
    const blob = new Blob([res.data], {
      type: res.headers['Content-Type']
    })
    a.href = URL.createObjectURL(blob)
    let filename = res.headers && res.headers['filename']
    if (filename) {
      filename = decodeURIComponent(filename)
    }
    a.download = filename
    a.click()
  })
}
