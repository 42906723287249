<template>
  <el-row>
    <el-col :span="24">
      <el-select 
        v-model="grade"
        filterable
        clearable
        multiple
        collapse-tags
        placeholder="年级" 
        style="width: 100%"
        @change="gradeChange"
      >
        <el-option
          v-for="item in gradeList"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import { gradeList } from '@/api/shottr/student'
export default {
  data() {
    return {
      gradeList: [],
      grade: []
    }
  },
  created() {
    this.initGradeList()
  },
  methods: {
    initGradeList() {
      gradeList().then(({ code, data, message }) => {
        this.gradeList = []
        if (code === 200) {
          this.gradeList = data || []
        } else {
          this.$message.error('系统异常: ' + message)
        }
      })
    },
    gradeChange() {
      this.$emit('gradeChange', this.grade)
    }
  }
}
</script>

<style scoped>

</style>
