import request from '@/utils/request'

/**
 * 根据用户id查询绑定的指标
 * @param { 用户id } uid 
 * @returns 结果集
 */
export function getUserIndexByUid(uid) {
  return request({
    url: '/user/index/get/' + uid,
    method: 'get'
  })
}

/**
 * 保存用户可查看指标
 * @param { 组件类别对象 } data 
 * @returns result { code: 200, message: '', data: {} }
 */
export function saveUserIndex(data) {
  return request({
    url: '/user/index/insert/list',
    method: 'post',
    data
  })
}
