export const option = {
  backgroundColor: '#fff',
  title: {
    text: '', // TODO
    left: 'center'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    left: '3%',
    right: '6%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    name: '', // TODO
    type: 'category',
    data: [] // TODO X轴名称
  },
  yAxis: {
    name: '', // TODO
    type: 'value',
    splitArea: {
      show: false
    },
    splitLine: {
      show: false
    }
  },
  series: [
    {
      data: [], // TODO 折线图值
      type: 'bar',
      barWidth: 20,
      label: {
        normal: {
          show: true,
          position: 'top'
        }
      }
    }
  ]
}