import request from '@/utils/request'

/**
 * 查询所有指标字典
 * @param { 查询参数 } params 
 * @returns 分页结果集
 */
export function indexDictList() {
  return request({
    url: '/index/dict/list',
    method: 'get'
  })
}

export function updateIndexDict(data) {
  return request({
    url: '/index/dict/update',
    method: 'post',
    data
  })
}
