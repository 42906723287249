import request from '@/utils/request'

/**
 * 获得所有的学年-学期-第几期数据
 * @returns phase list
 */
export function list() {
  return request({
    url: '/phase/list',
    method: 'GET'
  })
}

/**
 * 查询学年集合
 * @returns 学年集合
 */
export function schoolYearList() {
  return request({
    url: '/phase/school/year/list',
    method: 'GET'
  })
}

/**
 * 根据学年获取学期
 * @param {params} params 
 * @returns 学期集合
 */
export function semesterList(params) {
  return request({
    url: '/phase/semester/list',
    method: 'GET',
    params
  })
}

/**
 * 根据学年学期查询期数据
 * @param {params} params 
 * @returns 期数据集合
 */
export function phaseList(params) {
  return request({
    url: '/phase/list/options',
    method: 'GET',
    params
  })
}