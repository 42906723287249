<template>
  <div class="container">
    <div class="pd-16" style="height: 100%; overflow: auto">
      <!-- 表格 -->
      <el-table
        border
        :data="list"
        style="width: 100%"
        :height="tableHeight"
        class="mt-16"
      >
        <el-table-column prop="role" label="角色" width="180" />
        <el-table-column prop="name" label="权限" min-width="180">
          <template slot-scope="{ row }">
            <span v-if="!row.name">-</span>
            <div v-else>
              <el-tag v-for="item in row.name.split(',')" :key="item" style="margin-left: 8px; margin-bottom: 8px;">{{ item }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="{ row }">
            <el-tooltip content="修改" placement="top">
              <el-popconfirm
                :title="'是否修改当前权限信息'"
                icon="el-icon-edit-outline"
                icon-color="#409EFF"
                placement="top-end"
                @confirm="toUpdate(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-edit-outline p8" style="color: #409eff" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 信息编辑弹出框 -->
    <el-dialog
      :visible.sync="editDialog"
      width="800px"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeDialog"
    >
      <span slot="title"> 角色权限设置 </span>
      <el-transfer
        filterable
        filter-placeholder="菜单"
        v-model="menuIds"
        :data="menus"
        style="width: 800px;"
      />
      <center>
        <el-button
          type="primary"
          @click="submitForm"
          style="width: 160px; margin-top: 16px"
        >
          保 存
        </el-button>
      </center>
      <el-alert
        title="温馨提示"
        show-icon
        description="本次修改权限需重新登录方可生效！！!"
        type="info"
        :closable="false"
        style="margin-top: 16px;"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getAllRoleMenu, getUpdateDetail, coverRoleMenu } from '@/api/shottr/role-menu'
export default {
  data() {
    return {
      list: [],
      menuIds: [],
      menus: [],
      role: '',
      editDialog: false,
      tableHeight: 600
    }
  },
  created() {
    this.queryData()
    this.$nextTick(() => {
      this.resetHeight()
    })
  },
  mounted() {
    window.onresize = () => {
      this.resetHeight()
    }
  },
  methods: {
    resetHeight() {
      const pageHeight = document.getElementById('phase-page').clientHeight
      const menuHeight = document.getElementById("menuId").clientHeight
      const insertBtnHeight = document.getElementById('phase-insert-btn').clientHeight
      this.tableHeight = window.innerHeight - menuHeight - insertBtnHeight - pageHeight - 100
    },
    queryData() {
      getAllRoleMenu().then(({ code, data, total }) => {
        this.list = []
        if (code === 200 && data) {
          this.list = data
        }
      })
    },
    toUpdate(row) {
      this.role = row.role
      // 根据角色获得选中的菜单id集合
      getUpdateDetail(row.role).then(({ code, data }) => {
        if (code === 200 && data) {
          var newMenus = []
          if (data.all) {
            data.all.forEach(val => {
              newMenus.push({
                key: val.id,
                label: val.name
              })
            })
          }
          this.menus = newMenus
          this.menuIds = data.sel
        }
        this.editDialog = true
      })
    },
    submitForm() {
      const pData = {
        role: this.role,
        menuIds: this.menuIds
      }
      coverRoleMenu(pData).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('保存成功')
          this.closeDialog()
          this.queryData()
        } else {
          this.$message.error('保存失败, ' + message)
        }
      })
    },
    closeDialog() {
      this.role = ''
      this.menus = []
      this.menuIds = []
      this.editDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  /* height: calc(100vh - 115px); */
  overflow: hidden;
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .el-transfer-panel {
  width: 35%;
}
</style>
