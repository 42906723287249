import request from '@/utils/request'

// 访问地址前缀
const prefix = '/role/menu'

/**
 * 获得所有角色分配的权限信息
 */
export function getAllRoleMenu() {
  return request({
    url: prefix + '/all',
    method: 'GET'
  })
}

/**
 * 根据角色获取权限明细
 */
export function getUpdateDetail(role) {
  return request({
    url: prefix + '/update/detail/' + role,
    method: 'GET'
  })
}

/**
 * 根据当前登录用户获取角色权限列表
 */
export function getRoleMenuByUser() {
  return request({
    url: prefix + '/user',
    method: 'GET'
  })
}

/**
 * 设置角色权限
 */
export function coverRoleMenu(data) {
  return request({
    url: prefix + '/cover',
    method: 'POST',
    data
  })
}
