import request from '@/utils/request'

/**
 * 导入学生开学率Excel
 * @param {data} data 
 * @returns success rows
 */
export function importExcel(data) {
  return request({
    url: '/reference/import',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data
  })
}
