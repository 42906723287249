<template>
  <div class="container">
    <div class="pd-16" id="student-insert-btn">
      <el-row>
        <!-- <el-col :span="4">
          <el-input
            v-model="paramName"
            clearable
            placeholder="姓名/用户名"
            @keyup.native.enter="refresh"
            @clear="refresh"
          />
        </el-col> -->
        <el-col :span="24" class="insert-btn-center">
          <el-tooltip :content="'新增' + templateName" placement="top">
            <a class="icon-btn" @click="toInsert">
              <i
                class="el-icon-circle-plus-outline p8"
                style="color: #409eff"
              />
            </a>
          </el-tooltip>
          <el-tooltip :content="'导入' + templateName" placement="top">
            <a class="icon-btn" @click="toImport">
              <i
                class="el-icon-upload2 p8"
                style="color: #409eff"
              />
            </a>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
    <div class="pd-16" style="height: 100%; overflow: auto">
      <!-- 表格 -->
      <el-table
        border
        :data="list"
        style="width: 100%"
        :max-height="tableHeight"
        class="mt-16"
      >
        <el-table-column prop="gradeName" label="年级" min-width="180" />
        <el-table-column prop="className" label="班级" min-width="180" />
        <el-table-column prop="student" label="学生名称" min-width="180" />
        <el-table-column label="操作" min-width="180">
          <template slot-scope="{ row }">
            <el-tooltip content="修改" placement="top">
              <el-popconfirm
                :title="'是否修改当前' + templateName + '信息'"
                icon="el-icon-edit-outline"
                icon-color="#409EFF"
                placement="top-end"
                @confirm="toUpdate(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-edit-outline p8" style="color: #409eff" />
                </a>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <el-popconfirm
                :title="'是否删除当前' + templateName + '信息？'"
                icon="el-icon-delete"
                icon-color="#F56C6C"
                placement="top-end"
                @confirm="remove(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-delete p8" style="color: #f56c6c" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="mt-8" id="student-page" style="text-align: right">
        <el-pagination
          background
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :total="tTotal"
          :page-sizes="[15, 30, 60, 90]"
          small
          layout="total, sizes, prev, pager, next, jumper"
          class="fr"
          @size-change="
            (size) => {
              page.pageSize = size;
            }
          "
          @current-change="
            (num) => {
              page.pageNum = num;
            }
          "
        />
      </el-row>
    </div>
    <!-- 信息编辑弹出框 -->
    <el-dialog
      :visible.sync="editDialog"
      width="600px"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeDialog"
    >
      <span slot="title"> 编辑{{ templateName }}信息 </span>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item label="年级" prop="gradeName">
          <el-input v-model="ruleForm.gradeName" maxlength="16" show-word-limit />
        </el-form-item>
        <el-form-item label="班级" prop="className">
          <el-input v-model="ruleForm.className" maxlength="16" show-word-limit />
        </el-form-item>
        <el-form-item label="学生名称" prop="student">
          <el-input v-model="ruleForm.student" maxlength="16" show-word-limit />
        </el-form-item>
        <el-form-item>
          <center>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              >保 存</el-button
            >
          </center>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="importDialog"
      width="390px"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeImportDialog"
    >
      <span slot="title"> 导入{{ templateName }}信息 </span>
      <!-- 127.0.0.1:1020 -->
      <!-- https://www.tanhuasu.club/shottr -->
      <el-upload
        ref="importFile"
        class="upload-demo"
        drag
        action="https://www.tanhuasu.club/shottr/student/import"
        :multiple="false"
        :on-success="uploadStudentSuccess"
        :on-error="uploadStudentError"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件</div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import { page, edit, remove } from '@/api/shottr/base'
export default {
  data() {
    return {
      templateName: '学生',
      prefix: '/student/',
      list: [],
      page: {
        pageNum: 1,
        pageSize: 15
      },
      tTotal: 0,
      editDialog: false,
      importDialog: false,
      ruleForm: {
        gradeName: '',
        className: '',
        student: ''
      },
      rules: {
        gradeName: [
          { required: true, message: '年级不能为空', trigger: 'blur' }
        ],
        className: [
          { required: true, message: '班级不能为空', trigger: 'blur' }
        ],
        student: [
          { required: true, message: '学生名称不能为空', trigger: 'blur' }
        ]
      },
      tableHeight: 600
    }
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.queryData()
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.resetHeight()
    })
  },
  mounted() {
    window.onresize = () => {
      this.resetHeight()
    }
  },
  methods: {
    resetHeight() {
      const pageHeight = document.getElementById('student-page').clientHeight
      const menuHeight = document.getElementById("menuId").clientHeight
      const insertBtnHeight = document.getElementById('student-insert-btn').clientHeight
      this.tableHeight = window.innerHeight - menuHeight - insertBtnHeight - pageHeight - 100
    },
    refresh() {
      if (this.page.pageNum !== 1) {
        this.page.pageNum = 1
      } else {
        this.queryData()
      }
    },
    queryData() {
      page(this.prefix, this.page).then(({ code, data, total }) => {
        this.tTotal = 0
        this.list = []
        if (code === 200 && data) {
          this.tTotal = total
          this.list = data
        }
      })
    },
    toInsert() {
      this.editDialog = true
    },
    toUpdate(row) {
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.editDialog = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          edit(this.prefix, this.ruleForm).then(({ code, message }) => {
            if (code === 200) {
              this.$message.success('保存成功')
              this.closeDialog()
              this.refresh()
            } else {
              this.$message.error('保存失败, ' + message)
            }
          })
        }
      })
    },
    closeDialog() {
      this.ruleForm = {
        gradeName: '',
        className: '',
        student: ''
      }
      this.$refs['ruleForm'].resetFields()
      this.editDialog = false
    },
    remove(row) {
      remove(this.prefix, row.id).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('删除成功')
          this.refresh()
        } else {
          this.$message.error('删除失败,' + message)
        }
      })
    },
    toImport() {
      this.importDialog = true
    },
    closeImportDialog() {
      this.$refs.importFile.clearFiles()
      this.importDialog = false
      this.refresh()
    },
    uploadStudentSuccess(response, file, fileList) {
      this.$message.success('成功导入' + response.data + '条记录')
      this.closeImportDialog()
    },
    uploadStudentError(err, file, fileList) {
      this.$message.success('成功失败:' + JSON.stringify(err))
      this.$refs.importFile.clearFiles()
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  /* height: calc(100vh - 115px); */
  overflow: hidden;
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
