import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '../components/login'
import home from '../components/home'
// 用户主页
import Main from '../components/main'

// 管理员主页
import AdminHome from '../adminPage/home'
import AdminMain from '../adminPage/admin'

// shottr
import Phase from '../components/phase'
import Student from '../components/student'
import Reference from '../components/reference'
import ParticipationRate from '../components/participation-rate'

// activity
import ActivityManager from '../components/activity-manager'
import Newsletter from '../components/newsletter'

// role menu
import RoleMenu from '../components/role-menu'

// student sign
import StudentSign from '../components/sign'

Vue.use(VueRouter);

const routes = [
  {
    name: '',
    path: '/',
    redirect: '/login',
  },
  {
    name: 'login',
    path: '/login',
    component: login,
    meta: {
      title: '登录',
      type: '', // 是否需要判断是否登录,非login不需要判断
    },
  },
  {
    name: 'home',
    path: '/home',
    component: home,
    meta: {
      title: '首页',
      type: 'login', // 是否需要判断是否登录,login表示需要判断
    },
    children: [
      {
        path: 'main',
        component: Main,
        meta: {
          title: '主页',
          type: 'login'
        }
      },
      {
        path: 'student-sign',
        component: StudentSign,
        meta: {
          title: '学生签到看板',
          type: 'login'
        }
      },
      {
        path: 'phase',
        component: Phase,
        meta: {
          title: '学期信息管理',
          type: 'login'
        }
      },
      {
        path: 'student',
        component: Student,
        meta: {
          title: '学生信息管理',
          type: 'login'
        }
      },
      {
        path: 'reference',
        component: Reference,
        meta: {
          title: '学生开学率管理',
          type: 'login'
        }
      },
      {
        path: 'participation-rate',
        component: ParticipationRate,
        meta: {
          title: '参学率视图',
          type: 'login'
        }
      },
      {
        path: 'activity-manager',
        component: ActivityManager,
        meta: {
          title: '团日活动设置',
          type: 'login'
        }
      },
      {
        path: 'newsletter',
        component: Newsletter,
        meta: {
          title: '通讯稿在线考核',
          type: 'login'
        }
      },
      {
        path: 'role-menu',
        component: RoleMenu,
        meta: {
          title: '权限设置',
          type: 'login'
        }
      }
    ],
  },
  {
    name: 'admin',
    path: '/admin',
    component: AdminHome,
    meta: {
      title: '管理员首页',
      type: 'login', // 是否需要判断是否登录,login表示需要判断
    },
    children: [
      {
        path: 'admin-manager',
        component: AdminMain,
        meta: {
          title: '用户管理',
          type: 'login',
        },
      },
      {
        path: 'index-dict',
        component: () => import('@/adminPage/index-dict.vue'),
        meta: {
          title: '指标管理',
          type: 'login',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
