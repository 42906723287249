<template>
  <div class="container">
    <el-tabs type="border-card">
      <el-tab-pane label="总参学率" :lazy="true">
        <All />
      </el-tab-pane>
      <el-tab-pane label="年级参学率" :lazy="true">
        <Grade />
      </el-tab-pane>
      <el-tab-pane label="班级参学率" :lazy="true">
        <Class />
      </el-tab-pane>
      <el-tab-pane label="个人参学率" :lazy="true">
        <Personal />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import All from './all.vue'
import Grade from './grade.vue'
import Class from './class.vue'
import Personal from './personal.vue'
export default {
  components: {
    All,
    Class,
    Grade,
    Personal
  }
}
</script>

<style scoped>
  .container {
    width: 100%;
    overflow: hidden;
  }
</style>