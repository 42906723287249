<template>
  <div v-loading="selectLoading">
    <el-row :gutter="20">
      <el-col :span="8">
        <SchoolYearSemesterSelect @schoolYearChange="schoolYearChange" @semesterChange="semesterChange" />
      </el-col>
      <el-col :span="4">
        <StudentSelect @studentChange="studentChange" />
      </el-col>
      <el-col :span="4">
        <el-button icon="el-icon-search" @click="search">查询</el-button>
        <el-popconfirm
          :title="'是否确认导出个人参学率？'"
          icon="el-icon-download"
          icon-color="#409EFF"
          placement="top-end"
          @confirm="exportExcel"
        >
          <el-button slot="reference" icon="el-icon-download" style="margin-left: 8px;">导出</el-button>
        </el-popconfirm>
      </el-col>
    </el-row>
    <el-row>
      <div style="width: 100%; height: 500px; margin-top: 16px;">
        <!-- v-if="dataList && dataList.length > 0" v-model="dataList" -->
        <el-table
          v-if="list && list.length > 0"
          border
          :data="list"
          style="width: 100%"
          height="400px"
          class="mt-16"
        >
          <el-table-column prop="gradeName" label="年级" min-width="180" />
          <el-table-column prop="className" label="班级" min-width="180" />
          <el-table-column prop="student" label="学生名称" min-width="180" />
          <el-table-column prop="rate" label="参学率" min-width="180" />
        </el-table>
        <el-empty v-else description="暂无数据~"></el-empty>
      </div>
    </el-row>
  </div>
</template>

<script>
import SchoolYearSemesterSelect from './components/SchoolYearSemesterSelect.vue'
import StudentSelect from './components/StudentSelect.vue'
import { personal } from '@/api/shottr/participation-rate'
import { exportFile } from '@/api/shottr/base'
export default {
  components: {
    StudentSelect,
    SchoolYearSemesterSelect
  },
  data() {
    return {
      params: {
        schoolYear: '',
        semester: '',
        studentIdList: []
      },
      list: [],
      selectLoading: false
    }
  },
  methods: {
    schoolYearChange(value) {
      this.params.schoolYear = value
    },
    semesterChange(value) {
      this.params.semester = value
    },
    studentChange(value) {
      this.params.studentIdList = value
    },
    validate() {
      if (!this.params.schoolYear) {
        this.$message.warning('学年查询条件不能为空！')
        return false
      }
      if (!this.params.semester) {
        this.$message.warning('学期查询条件不能为空！')
        return false
      }
      return true
    },
    search() {
      if (!this.validate()) {
        return
      }
      this.selectLoading = true
      personal(this.params).then(({ code, data, message }) => {
        this.list = []
        if (code === 200) {
          if (data) {
            this.list = data
          }
        } else {
          this.$message.error('系统异常: ' + message)
        }
        this.selectLoading = false
      })
    },
    exportExcel() {
      if (!this.validate()) {
        return
      }
      this.selectLoading = true
      exportFile('/participation/rate/personal/export', 'POST', this.params)
      this.selectLoading = false
    }
  }
}
</script>

<style scoped>

</style>