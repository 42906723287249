<template>
  <div class="container">
    <div class="pd-16" id="phase-insert-btn">
      <el-row :gutter="20">
        <el-col :span="8">
          <SchoolYearSemesterSelect @schoolYearChange="schoolYearChange" @semesterChange="semesterChange" />
        </el-col>
        <el-col :span="4">
          <el-date-picker
            type="month"
            style="width: 100%;"
            value-format="yyyy-MM"
            v-model="query.month"
            placeholder="月份"
            @change="refresh()"
          />
        </el-col>
        <el-col :span="4">
          <el-input v-model="query.className" placeholder="班级模糊搜索" style="width: 100%;" @change="refresh()" />
        </el-col>
        <el-col :span="8" class="insert-btn-center">
          <el-tooltip v-if="btnRole.includes('activityInsert')" :content="'新增' + templateName" placement="top">
            <a class="icon-btn" @click="toInsert">
              <i
                class="el-icon-circle-plus-outline p8"
                style="color: #409eff"
              />
            </a>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
    <div class="pd-16" style="height: 100%; overflow: auto">
      <!-- 表格 -->
      <el-table
        border
        :data="list"
        style="width: 100%"
        :height="tableHeight"
        class="mt-16"
      >
        <el-table-column prop="activityName" label="活动名称" min-width="180" />
        <el-table-column prop="className" label="班级" min-width="180" />
        <el-table-column prop="scheduledTime" label="初定开展时间" min-width="180">
          <template slot-scope="{ row }">
            <el-date-picker
              v-model="row.scheduledTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="right"
              :picker-options="pickerOptions"
              style="width: 100%;"
            />
          </template>
        </el-table-column>
        <el-table-column prop="scheduledAddress" label="初定开展地点" min-width="180">
          <template slot-scope="{ row }">
            <el-input v-model="row.scheduledAddress" type="textarea" :rows="2" maxlength="32" show-word-limit />
          </template>
        </el-table-column>
        <el-table-column prop="checkUserName1" label="考核人1" min-width="180">
          <template slot-scope="{ row }">
            <UserSelect ref="checkUserPhone1" v-model="row.checkUserPhone1" @userChange="(phone) => {
              if (phone) {
                row.checkUserPhone1 = phone
              } else {
                row.checkUserPhone1 = ''
              }
            }" />
          </template>
        </el-table-column>
        <el-table-column prop="checkUserName2" label="考核人2" min-width="180">
          <template slot-scope="{ row }">
            <UserSelect ref="checkUserPhone2" v-model="row.checkUserPhone2" @userChange="(phone) => {
              if (phone) {
                row.checkUserPhone2 = phone
              } else {
                row.checkUserPhone2 = ''
              }
            }" />
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="80">
          <template slot-scope="{ row }">
            <el-tooltip v-if="!row.chargeUser && btnRole.includes('activityConfig')" content="设置" placement="top">
              <el-popconfirm
                :title="'是否设置除当前' + templateName + '？'"
                icon="el-icon-connection"
                icon-color="#409EFF"
                placement="top-end"
                @confirm="activityConfig(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-connection p8" style="color: #409EFF" />
                </a>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip v-if="assessQua(row) && btnRole.includes('activityCheck')" content="考核" placement="top">
              <el-popconfirm
                :title="'是否考核当前' + templateName + '？'"
                icon="el-icon-edit-outline"
                icon-color="#409EFF"
                placement="top-end"
                @confirm="toCheck(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-edit-outline p8" style="color: #409eff" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="mt-8" id="phase-page" style="text-align: right">
        <el-pagination
          background
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :total="tTotal"
          :page-sizes="[15, 30, 60, 90]"
          small
          layout="total, sizes, prev, pager, next, jumper"
          class="fr"
          @size-change="
            (size) => {
              page.pageSize = size;
            }
          "
          @current-change="
            (num) => {
              page.pageNum = num;
            }
          "
        />
      </el-row>
    </div>
    <!-- 信息编辑弹出框 -->
    <el-dialog
      :visible.sync="editDialog"
      width="1000px"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeDialog"
    >
      <span slot="title"> 新建{{ templateName }} </span>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item label="学年学期" prop="semester">
          <el-row>
            <el-col :span="24">
              <SchoolYearSemesterSelect
                ref="schoolYear" 
                @schoolYearChange="(val) => { ruleForm.schoolYear = val }" 
                @semesterChange="(val) => { ruleForm.semester = val }" 
              />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="月份" prop="month">
          <el-date-picker
            type="month"
            style="width: 100%;"
            value-format="yyyy-MM"
            v-model="ruleForm.month"
            placeholder="月份"
          />
        </el-form-item>
        <el-form-item label="团日活动" prop="activityName">
          <el-input v-model="ruleForm.activityName" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="班级" prop="gradeClassList">
          <el-transfer
            filterable
            filter-placeholder="班级"
            v-model="ruleForm.gradeClassList"
            :data="gradeClassList"
            style="width: 1000px;"
          />
        </el-form-item>
        <el-form-item>
          <center>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              >保 存</el-button
            >
          </center>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="activityDialog"
      width="900px"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeActivityDialog"
    >
      <ActivityScoreDetail v-model="checkActivity" @close="closeActivityDialog" />
    </el-dialog>
  </div>
</template>

<script>
import UserSelect from './components/UserSelect'
import ActivityScoreDetail from './components/ActivityScoreDetail'
import SchoolYearSemesterSelect from './participation-rate/components/SchoolYearSemesterSelect'
import GradeSelect from './participation-rate/components/GradeSelect'
import { page, edit } from '@/api/shottr/base'
import { activityConfig } from '@/api/shottr/activity'
import { gradeClassList } from '@/api/shottr/student'
export default {
  components:{
    UserSelect,
    GradeSelect,
    ActivityScoreDetail,
    SchoolYearSemesterSelect
  },
  data() {
    return {
      templateName: '团日活动',
      prefix: '/activity/',
      list: [],
      page: {
        pageNum: 1,
        pageSize: 15
      },
      query: {
        schoolYear: '',
        semester: '',
        month: '',
        className: ''
      },
      tTotal: 0,
      editDialog: false,
      ruleForm: {
        schoolYear: '',
        semester: '',
        month: '',
        activityName: '',
        gradeClassList: []
      },
      rules: {
        semester: [
          { required: true, message: '学年学期不能为空', trigger: 'blur' }
        ],
        month: [
          { required: true, message: '月份不能为空', trigger: 'blur' }
        ],
        activityName: [
          { required: true, message: '团日活动不能为空', trigger: 'blur' }
        ],
        gradeClassList: [
          { required: true, message: '班级不能为空', trigger: 'blur' }
        ]
      },
      checkActivity: {},
      activityDialog: false,
      tableHeight: 600,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      btnRole: [],
      gradeClassList: []
    }
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.queryData()
      }
    }
  },
  created() {
    this.formatRole()
    this.initGradeClassList()
    this.$nextTick(() => {
      this.resetHeight()
    })
  },
  mounted() {
    window.onresize = () => {
      this.resetHeight()
    }
  },
  methods: {
    initGradeClassList() {
      gradeClassList().then(({ code, data }) => {
        this.gradeClassList = []
        if (code === 200 && data) {
          data.forEach(val => {
            this.gradeClassList.push({
              key: val,
              label: val
            })
          })
        }
      })
    },
    formatRole() {
      const role = JSON.parse(localStorage.getItem('roleMenu'))
      if (role[2]) {
        role[2].forEach(val => {
          this.btnRole.push(val.path)
        })
      }
    },
    schoolYearChange(value) {
      this.query.schoolYear = value
      this.refresh()
    },
    semesterChange(value) {
      this.query.semester = value
      this.refresh()
    },
    resetHeight() {
      const pageHeight = document.getElementById('phase-page').clientHeight
      const menuHeight = document.getElementById("menuId").clientHeight
      const insertBtnHeight = document.getElementById('phase-insert-btn').clientHeight
      this.tableHeight = window.innerHeight - menuHeight - insertBtnHeight - pageHeight - 100
    },
    refresh() {
      if (this.page.pageNum !== 1) {
        this.page.pageNum = 1
      } else {
        this.queryData()
      }
    },
    queryData() {
      var param = {
        ...this.page,
        ...this.query
      }
      // 只能看自己考核的数据
      const role = JSON.parse(localStorage.getItem('roleMenu'))
      if (role[3]) {
        var bool = false
        role[3].forEach(val => {
          if (val.path === 'activityData') {
            bool = true
          }
        })
        if (bool) {
          param.phone = localStorage.getItem('userPhone')
        }
      }
      page(this.prefix, param).then(({ code, data, total }) => {
        this.tTotal = 0
        this.list = []
        if (code === 200 && data) {
          this.tTotal = total
          this.list = data
        }
      })
    },
    toInsert() {
      this.editDialog = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          edit(this.prefix, this.ruleForm).then(({ code, message }) => {
            if (code === 200) {
              this.$message.success('保存成功')
              this.closeDialog()
              this.refresh()
            } else {
              this.$message.error('保存失败, ' + message)
            }
          })
        }
      })
    },
    closeDialog() {
      this.$refs.schoolYear.clear()
      this.ruleForm = {
        schoolYear: '',
        semester: '',
        month: '',
        activityName: '',
        gradeClassList: []
      }
      this.$refs['ruleForm'].resetFields()
      this.editDialog = false
    },
    toCheck(row) {
      this.checkActivity = JSON.parse(JSON.stringify(row))
      this.activityDialog = true
    },
    closeActivityDialog() {
      this.checkActivity = {}
      this.activityDialog = false
      this.queryData()
    },
    activityConfig(row) {
      if (!(this.validateRowParam(row.scheduledTime, '初始化时间不能为空') 
          && this.validateRowParam(row.scheduledAddress, '初始化事件不能为空')
          && this.validateRowParam(row.checkUserPhone1, '考核人1不能为空')
          && this.validateRowParam(row.checkUserPhone2, '考核人2不能为空')))
      {
        return
      }
      const pData = {
        id: row.id,
        scheduledTime: row.scheduledTime,
        scheduledAddress: row.scheduledAddress,
        phone1: row.checkUserPhone1,
        phone2: row.checkUserPhone2
      }
      activityConfig(pData).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('设置成功')
          // 这里可替换 修改name不为空即可
          this.$refs.checkUserPhone1.clearValue()
          this.$refs.checkUserPhone2.clearValue()
          this.queryData()
        } else {
          this.$message.error('设置失败, ' + message)
        }
      })
    },
    validateRowParam(val, message) {
      if (!val) {
        this.$message.error(message)
        return false
      }
      return true
    },
    // 是否有资格考核
    assessQua(row) {
      // 已经进行活动设置且活动考核人其中一个是自己
      const userPhone = localStorage.getItem('userPhone')
      const userName = localStorage.getItem('userName')
      return row.checkUserName1 
        && ( (row.checkUserPhone1 === userPhone && row.checkUserName1 === userName) 
            || (row.checkUserPhone2 === userPhone && row.checkUserName2 === userName))
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  /* height: calc(100vh - 115px); */
  overflow: hidden;
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .el-transfer-panel {
  width: 35%;
}
</style>
