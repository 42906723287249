<template>
  <div class="container">
    <div class="pd-16" id="phase-insert-btn">
      <el-row :gutter="20">
        <el-col :span="8">
          <SchoolYearSemesterSelect @schoolYearChange="schoolYearChange" @semesterChange="semesterChange" />
        </el-col>
        <el-col :span="4">
          <el-date-picker
            type="month"
            style="width: 100%;"
            value-format="yyyy-MM"
            v-model="query.month"
            placeholder="月份"
            @change="refresh()"
          />
        </el-col>
      </el-row>
    </div>
    <div class="pd-16" style="height: 100%; overflow: auto">
      <!-- 表格 -->
      <el-table
        border
        :data="list"
        style="width: 100%"
        :height="tableHeight"
        class="mt-16"
      >
        <el-table-column prop="activityName" label="活动名称" min-width="180" />
        <el-table-column prop="className" label="班级" min-width="180" />
        <el-table-column prop="score" label="考核总分" width="70" />
        <el-table-column prop="checkUserName1" label="考核人1" width="70" />
        <el-table-column prop="checkUserName2" label="考核人2" width="70" />
        <el-table-column prop="scheduledNumber" label="应到人数" width="70" />
        <el-table-column prop="actualNumber" label="实到人数" width="70" />
        <el-table-column prop="scheduledTime" label="初定开展时间" width="150" />
        <el-table-column prop="actualTime" label="实际开展时间" width="150" />
        <el-table-column prop="scheduledAddress" label="初定开展地点" min-width="180" />
        <el-table-column prop="actualAddress" label="实际开展地点" min-width="180" />
        <el-table-column prop="chargeUser" label="负责人" width="70" />
        <el-table-column label="操作" width="60">
          <template slot-scope="{ row }">
            <el-tooltip v-if="btnRole.includes('newletterCheck')" content="评分" placement="top">
              <el-popconfirm
                :title="'是否对' + row.activityName + '团日活动进行评分？'"
                icon="el-icon-edit-outline"
                icon-color="#409EFF"
                placement="top-end"
                @confirm="toSetScore(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-edit-outline p8" style="color: #409eff" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="mt-8" id="phase-page" style="text-align: right">
        <el-pagination
          background
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :total="tTotal"
          :page-sizes="[15, 30, 60, 90]"
          small
          layout="total, sizes, prev, pager, next, jumper"
          class="fr"
          @size-change="
            (size) => {
              page.pageSize = size;
            }
          "
          @current-change="
            (num) => {
              page.pageNum = num;
            }
          "
        />
      </el-row>
    </div>
    <!-- 信息编辑弹出框 -->
    <el-dialog
      :visible.sync="editDialog"
      width="600px"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeDialog"
    >
      <span slot="title"> 通讯稿在线考核 </span>
      <el-form
        label-width="100px"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item label="团日活动">
          {{ rowCopy.activityName }}
        </el-form-item>
        <el-form-item label="分数">
          <el-input-number v-model="rowCopy.newsletterScore" :min="0" :max="100" />
        </el-form-item>
        <el-form-item>
          <center>
            <el-button
              type="primary"
              @click="submitForm()"
              >保 存</el-button
            >
          </center>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SchoolYearSemesterSelect from './participation-rate/components/SchoolYearSemesterSelect'
import { page } from '@/api/shottr/base'
import { activityNewsletterScore } from '@/api/shottr/activity'
export default {
  components:{
    SchoolYearSemesterSelect
  },
  data() {
    return {
      list: [],
      page: {
        pageNum: 1,
        pageSize: 15
      },
      query: {
        schoolYear: '',
        semester: '',
        month: ''
      },
      tTotal: 0,
      editDialog: false,
      rowCopy: {},
      tableHeight: 600,
      btnRole: []
    }
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.queryData()
      }
    }
  },
  created() {
    this.formatRole()
    this.$nextTick(() => {
      this.resetHeight()
    })
  },
  mounted() {
    window.onresize = () => {
      this.resetHeight()
    }
  },
  methods: {
    formatRole() {
      const role = JSON.parse(localStorage.getItem('roleMenu'))
      if (role[2]) {
        role[2].forEach(val => {
          this.btnRole.push(val.path)
        })
      }
    },
    schoolYearChange(value) {
      this.query.schoolYear = value
      this.refresh()
    },
    semesterChange(value) {
      this.query.semester = value
      this.refresh()
    },
    resetHeight() {
      const pageHeight = document.getElementById('phase-page').clientHeight
      const menuHeight = document.getElementById("menuId").clientHeight
      const insertBtnHeight = document.getElementById('phase-insert-btn').clientHeight
      this.tableHeight = window.innerHeight - menuHeight - insertBtnHeight - pageHeight - 100
    },
    refresh() {
      if (this.page.pageNum !== 1) {
        this.page.pageNum = 1
      } else {
        this.queryData()
      }
    },
    queryData() {
      const param = {
        ...this.page,
        ...this.query,
        isCheck: '1'
      }
      page('/activity/', param).then(({ code, data, total }) => {
        this.tTotal = 0
        this.list = []
        if (code === 200 && data) {
          this.tTotal = total
          this.list = data
        }
      })
    },
    toSetScore(row) {
      this.rowCopy = JSON.parse(JSON.stringify(row))
      this.editDialog = true
    },
    submitForm() {
      if (!this.rowCopy.newsletterScore) {
        this.$message.warning('分数不能为空')
        return
      }
      const pData = {
        id: this.rowCopy.id,
        newsletterScore: this.rowCopy.newsletterScore
      }
      activityNewsletterScore(pData).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('评分成功')
          this.closeDialog()
          this.refresh()
        } else {
          this.$message.error('评分失败, ' + message)
        }
      })
    },
    closeDialog() {
      this.rowCopy = {}
      this.editDialog = false
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  /* height: calc(100vh - 115px); */
  overflow: hidden;
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
