export const option = {
  backgroundColor: '#fff',
  title: {
    show: false,
    text: '', // TODO
    left: 'center'
  },
  grid: {
    left: '3%',
    right: '6%',
    bottom: '3%',
    containLabel: true
  },
  series: [
    { //第二个球的填充
      type: 'liquidFill',
      radius: '67%',
      color: [
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#E6A23C'
              },
              {
                offset: 1,
                color: '#08bbc9'
              },
            ],
            globalCoord: false
          }
      ],
      data: [], // data个数代表波浪数
      backgroundStyle: {
        borderWidth: 1,
        color: 'rgb(244, 244, 245)'
      },
      label: {
        normal: {
          textStyle: {
            fontSize: 28,
            color: '#fff'
          }
        }
      },
      outline: {
        // show: false
        borderDistance: 0,
        itemStyle: {
          borderWidth: 0,
          // borderColor: '#112165'
        }
      }
    }
  ]
}