<template>
  <el-container>
    <el-header>
      <div class="menu" id="menuId">
        <el-menu :router="true" :default-active="activeIndex" class="el-menu-demo" 
          background-color="#fff"
          text-color="rgba(0,0,0,0.7)"
          active-text-color="#409EFF"
          mode="horizontal"
        >
          <el-menu-item index="/home/main">主页</el-menu-item>

          <el-menu-item v-for="item in roleMenu[1]" :key="item.id" :index="item.path">{{ item.name }}</el-menu-item>
          <!-- <el-menu-item index="/home/student">学生信息管理</el-menu-item>
          <el-menu-item index="/home/reference">学生开学率管理</el-menu-item>
          <el-menu-item index="/home/participation-rate">参学率统计</el-menu-item>
          <el-menu-item index="/home/activity-manager">团日活动设置</el-menu-item>
          <el-menu-item index="/home/newsletter">通讯稿在线考核</el-menu-item>
          <el-menu-item index="/home/role-menu">权限设置</el-menu-item> -->
          

          <!-- <el-submenu index="3">
            <template slot="title">示例</template>
            <el-menu-item @click="newSystemNotice = true" >示例</el-menu-item>
            <el-menu-item index="/adminHome/adminSystemNotice" >示例</el-menu-item>
          </el-submenu> -->
          <el-submenu index="8" style="position:absolute;right:10px">
            <template slot="title"><i class="el-icon-s-custom"></i><span>{{user.name}}</span></template>
            <el-menu-item @click="updatePassword">修改密码</el-menu-item>
            <el-menu-item @click="logout">注销登录</el-menu-item>
            <el-menu-item @click="tableDrawer = true">个人信息</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <el-drawer
        title="个人信息"
        :visible.sync="tableDrawer"
        direction="rtl"
        size="50%"
      >
        <el-descriptions title="" border :column="1">
          <el-descriptions-item label="姓名">{{ user.name }}</el-descriptions-item>
          <el-descriptions-item label="电话号码">{{ user.phone }}</el-descriptions-item>
          <el-descriptions-item label="部门">{{ user.dept }}</el-descriptions-item>
          <el-descriptions-item label="角色权限">{{ user.role }}</el-descriptions-item>
        </el-descriptions>
      </el-drawer>
    </el-header>
    <el-main>
        <router-view></router-view>
    </el-main>
    <div id="footer" ></div>
  </el-container>
</template>

<script>
  import { info, updatePwd } from '@/api/users'
  import { getRoleMenuByUser } from '@/api/shottr/role-menu'
  export default {
    data() {
      return {
        user: '',
        tableDrawer: false,
        activeIndex: '/home/main',
        roleMenu: {}
      }
    },
    methods: {
      getRoleMenu() {
        getRoleMenuByUser().then(({ data }) => {
          this.roleMenu = data
          localStorage.setItem('roleMenu', JSON.stringify(data))
        })
      },
      updatePassword() {
        const result = {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          inputPlaceholder: '密码'
        }
        this.$prompt('请输入您的新密码', '提示', result).then(({ value }) => {
          const params = {
            phone: this.user.phone,
            password: value
          }
          updatePwd(params).then(({ code }) => {
            if(code === 200){
              this.$message({
                type:'success',
                message: '修改成功'
              })
              localStorage.removeItem('access_token')
              localStorage.removeItem('userPhone')
              localStorage.removeItem('userName')
              localStorage.removeItem('roleMenu')
              this.$router.push('/login')
            } else {
              this.$message({
                type:'warning',
                message: '修改密码异常, 请稍后重试'
              })
            }
          })
        }).catch(() => {})
      },
      logout() {
        localStorage.removeItem('access_token')
        localStorage.removeItem('userPhone')
        localStorage.removeItem('userName')
        localStorage.removeItem('roleMenu')
        this.$message.success('已注销，请重新登录')
        this.$router.push('/login')
      }
    },
    created() {
      this.getRoleMenu()
      info().then(({ data }) => {
        this.user = data
        localStorage.setItem('userName', data.name)
        this.$router.push({ path: '/home/main' })
      })
    }
  }
</script>

<style>
  #footer {
    height: 20px;
    padding-top: 2px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #F5F6F5;
    color: #282928;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
  #submit-button{
    text-align: center;
    margin-right:13% ;
    margin-top: 20px;
  }
  .tip{
    margin: 8px 0 5px 15px;
  }
  #select-button{
    margin-left: 16px;
  }
  .menu{
    padding-top: 10px;
  }
  .mt-8 {
    margin-bottom: 8px;
  }
  .mt-16 {
    margin-bottom: 16px;
  }
  .pd-16 {
    width: calc(100% - 32px);
    padding: 16px;
    background: #fff;
    margin-bottom: 8px;
  }
  .p8 {
    padding: 8px;
    cursor: pointer;
  }
</style>