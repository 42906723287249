<template>
  <div class="admin-parent-div">
    <!-- 条件、新增按钮 -->
    <div class="pd-16">
      <el-row>
        <el-col :span="4">
          <el-input
            v-model="paramName"
            clearable
            placeholder="姓名/用户名"
            @keyup.native.enter="refresh"
            @clear="refresh"
          />
        </el-col>
        <el-col :span="20" class="insert-btn-center">
          <el-tooltip content="新增" placement="top">
            <a class="icon-btn" @click="toInsert">
              <i
                class="el-icon-circle-plus-outline p8"
                style="color: #409eff"
              />
            </a>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
    <div class="pd-16" style="height: calc(100% - 110px)">
      <!-- 表格 -->
      <el-table border :data="adminList" style="width: 100%" class="mt-16">
        <el-table-column prop="user" label="用户名" min-width="180" />
        <el-table-column prop="name" label="姓名" min-width="180" />
        <el-table-column prop="dataId" label="飞机ID" min-width="180" />
        <el-table-column prop="status" label="权限" min-width="180">
          <template slot-scope="{ row }">
            <el-tag>{{ row.status === 0 ? "无人机" : "管理员" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="权限" min-width="180">
          <template slot-scope="{ row }">
            <el-tag v-if="row.activate === 1" type="success">已激活</el-tag>
            <el-tag v-else type="danger">未激活</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="{ row }">
            <el-tooltip content="修改" placement="top">
              <el-popconfirm
                title="是否修改当前用户信息？"
                icon="el-icon-edit-outline"
                icon-color="#409EFF"
                placement="top-end"
                @confirm="updateAdmin(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-edit-outline p8" style="color: #409eff" />
                </a>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip
              :content="row.activate === 1 ? '取消激活' : '用户激活'"
              placement="top"
            >
              <el-popconfirm
                :title="
                  '是否' +
                  (row.activate === 1 ? '取消激活当前用户？' : '激活当前用户？')
                "
                :icon="row.activate === 1 ? 'el-icon-turn-off' : 'el-icon-open'"
                :icon-color="row.activate === 1 ? '#F56C6C' : '#409EFF'"
                placement="top-end"
                @confirm="updateActivate(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i
                    :class="[
                      row.activate === 1 ? 'el-icon-turn-off' : 'el-icon-open',
                      'p8',
                    ]"
                    :style="{
                      color: row.activate === 1 ? '#F56C6C' : '#409EFF',
                    }"
                  />
                </a>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip
              v-if="row.status === 0"
              content="分配指标"
              placement="top"
            >
              <a class="icon-btn" @click="setUserIndex(row)">
                <i class="el-icon-connection p8" style="color: #409eff" />
              </a>
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <el-popconfirm
                title="是否删除当前用户信息？"
                icon="el-icon-delete"
                icon-color="#F56C6C"
                placement="top-end"
                @confirm="deleteAdmin(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-delete p8" style="color: #f56c6c" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="mt-8" style="text-align: right">
        <el-pagination
          background
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :total="tTotal"
          :page-sizes="[15, 30, 60, 90]"
          small
          layout="total, sizes, prev, pager, next, jumper"
          class="fr"
          @size-change="
            (size) => {
              page.pageSize = size;
            }
          "
          @current-change="
            (num) => {
              page.pageNum = num;
            }
          "
        />
      </el-row>
    </div>
    <!-- 人员编辑弹出框 -->
    <el-dialog
      :visible.sync="adminDialog"
      width="60vw"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeDialog"
    >
      <span slot="title"> 人员操作 </span>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item label="账号" prop="user">
          <el-input
            v-if="!ruleForm.id"
            v-model="ruleForm.user"
            maxlength="30"
            show-word-limit
          />
          <span v-else>{{ ruleForm.user }}</span>
          <el-alert
            title="账号一旦定义不可更改"
            type="info"
            :closable="false"
          />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="ruleForm.password"
            maxlength="18"
            show-password
            show-word-limit
            :placeholder="ruleForm.id ? '不输入则不修改' : '密码'"
          />
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" maxlength="16" show-word-limit />
        </el-form-item>
        <el-form-item label="飞机ID" prop="dataId">
          <el-input v-model="ruleForm.dataId" maxlength="16" show-word-limit />
        </el-form-item>
        <!-- <el-form-item label="角色权限" prop="status">
          <el-radio v-model="ruleForm.status" :label="0">无人机</el-radio>
          <el-radio v-model="ruleForm.status" :label="1">管理员</el-radio>
        </el-form-item> -->
        <el-form-item>
          <center>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              style="width: 200px"
              >提 交</el-button
            >
          </center>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="indexDialog"
      width="630px"
      top="3%"
      :close-on-click-modal="false"
      @closed="indexDialogClose"
    >
      <span slot="title"> 用户指标设置 </span>
      <el-transfer
        filterable
        :filter-method="filterMethod"
        filter-placeholder="可搜索指标"
        v-model="indexDataValues"
        :data="indexDataList"
      />
      <center>
        <el-button
          type="primary"
          @click="submitUserIndex"
          style="width: 200px; margin-top: 16px"
          >保 存</el-button
        >
      </center>
    </el-dialog>
  </div>
</template>

<script>
import { indexDictList } from "@/api/index-dict-api";
import { getUserIndexByUid, saveUserIndex } from "@/api/user-index-api";
export default {
  data() {
    return {
      paramName: "",
      page: {
        pageNum: 1,
        pageSize: 15,
      },
      tTotal: 0,
      adminList: [],
      adminDialog: false,
      ruleForm: {
        name: "",
        password: "",
        user: "",
        dataId: "",
        status: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        user: [{ required: true, message: "请输入账号", trigger: "blur" }],
        dataId: [{ required: true, message: "请输入飞机ID", trigger: "blur" }],
      },
      // 穿梭框的数据
      indexDialog: false,
      editIndexUser: {},
      indexDataList: [],
      indexDataValues: [],
      filterMethod(query, item) {
        return item.pinyin.indexOf(query) > -1;
      },
    };
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.queryData();
      },
    },
  },
  created() {
    this.queryData();
    this.initIndexDataList();
  },
  methods: {
    setUserIndex(row) {
      this.editIndexUser = JSON.parse(JSON.stringify(row));
      // 获得当前用户的指标设置
      getUserIndexByUid(row.id).then(({ code, data }) => {
        if (code === 200 && data) {
          data.forEach((val) => {
            this.indexDataValues.push(val.indexName);
          });
        }
      });
      this.indexDialog = true;
    },
    submitUserIndex() {
      if (!this.indexDataValues) {
        this.$message.error("用户至少一个指标");
        return;
      }
      const pData = {
        dataList: this.indexDataValues,
        uid: this.editIndexUser.id,
      };
      saveUserIndex(pData).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success("绑定指标成功");
        } else {
          this.$message.error("绑定指标失败," + message);
        }
      });
    },
    indexDialogClose() {
      this.indexDialog = false;
      this.editIndexUser = {};
      this.indexDataValues = [];
    },
    initIndexDataList() {
      this.indexDataList = [];
      indexDictList().then(({ data, code }) => {
        if (code === 200 && data) {
          data.forEach((val, index) => {
            this.indexDataList.push({
              label: val.cname,
              key: val.name,
              pinyin: val.name,
            });
          });
        }
      });
    },
    refresh() {
      if (this.page.pageNum !== 1) {
        this.page.pageNum = 1;
      } else {
        this.queryData();
      }
    },
    queryData() {
      const params = {
        name: this.paramName,
        ...this.page,
      };
      this.axios.get("/admin/page", { params }).then(({ data }) => {
        this.tTotal = 0;
        this.adminList = [];
        if (data && data.data) {
          this.tTotal = data.total;
          this.adminList = data.data;
        }
      });
    },
    toInsert() {
      this.adminDialog = true;
    },
    updateAdmin(row) {
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.adminDialog = true;
    },
    submitForm(formName) {
      if (!this.ruleForm.id && !this.ruleForm.password) {
        this.$message.warning("请输入密码");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios.post("/admin/edit", this.ruleForm).then(({ data }) => {
            if (data.code === 200) {
              this.$message.success("操作成功");
              this.closeDialog();
              this.refresh();
            } else {
              this.$message.error("操作失败, " + data.message);
            }
          });
        }
      });
    },
    closeDialog() {
      this.ruleForm = {
        name: "",
        password: "",
        user: "",
        dataId: "",
        status: 0,
      };
      this.$refs["ruleForm"].resetFields();
      this.adminDialog = false;
    },
    deleteAdmin(row) {
      this.axios.get("/admin/delete/" + row.id).then(({ data }) => {
        if (data.code === 200) {
          this.$message.success("删除成功");
          this.refresh();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    updateActivate(rowObj) {
      var row = JSON.parse(JSON.stringify(rowObj));
      row.activate = row.activate === 0 ? 1 : 0;
      this.axios.post("/admin/edit", row).then(({ data }) => {
        if (data.code === 200) {
          this.$message.success("操作成功");
          this.refresh();
        } else {
          this.$message.error("操作失败, " + data.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.admin-parent-div {
  width: 100%;
  height: calc(100vh - 115px);
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
