<template>
  <el-container>
    <el-header>
      <div class="menu">
        <el-menu :router="true" :default-active="activeIndex" class="el-menu-demo" 
          background-color="#fff"
          text-color="rgba(0,0,0,0.7)"
          active-text-color="#409EFF"
          mode="horizontal"
        >
          <el-menu-item index="/admin/admin-manager">用户管理</el-menu-item>
          <el-menu-item index="/admin/index-dict">指标管理</el-menu-item>
          
          <!-- <el-submenu index="3">
            <template slot="title">示例</template>
            <el-menu-item @click="newSystemNotice = true" >示例</el-menu-item>
            <el-menu-item index="/adminHome/adminSystemNotice" >示例</el-menu-item>
          </el-submenu> -->
          <el-submenu index="8" style="position:absolute;right:10px">
            <template slot="title"><i class="el-icon-s-custom"></i><span>{{user.name}}</span></template>
            <el-menu-item @click="updatePassword">修改密码</el-menu-item>
            <el-menu-item @click="logout">注销登录</el-menu-item>
            <el-menu-item @click="tableDrawer = true">个人信息</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <el-drawer
        title="个人信息"
        :visible.sync="tableDrawer"
        direction="rtl"
        size="50%"
      >
        <el-descriptions title="" border :column="1">
          <el-descriptions-item label="姓名">{{ user.name }}</el-descriptions-item>
          <el-descriptions-item label="用户名">{{ user.user }}</el-descriptions-item>
          <el-descriptions-item label="角色权限">{{ user.status === 0 ? '管理员' : '用户' }}</el-descriptions-item>
        </el-descriptions>
      </el-drawer>
    </el-header>
    <el-main>
        <router-view></router-view>
    </el-main>
    <div id="footer" ></div>
  </el-container>
</template>

<script>

  export default {
    data() {
      return {
        user: '',
        tableDrawer: false,
        activeIndex: '/admin/admin-manager'
      }
    },
    methods: {
      updatePassword() {
        const result = {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          inputPlaceholder: '密码'
        }
        this.$prompt('请输入您的新密码', '提示', result).then(({ value }) => {
          const params = {
            id: this.user.id,
            password: value
          }
          this.axios.get('/admin/updatePassword', { params }).then(({ data }) => {
            if(data.code === 200){
              this.$message({
                type:'success',
                message: '修改成功'
              })
              this.user.password = value
              localStorage.setItem('user', JSON.stringify(this.user))
            } else {
              this.$message({
                type:'warning',
                message: '修改密码异常, 请稍后重试'
              })
            }
          })
        }).catch(() => {})
      },
      logout() {
        this.axios.get('/admin/logout')
        localStorage.removeItem('access_token')
        localStorage.removeItem('userPhone')
        this.$message.success('已注销，请重新登录')
        this.$router.push('/login')
      }
    },
    created() {
      this.user = JSON.parse(localStorage.getItem('user'))
      this.$router.push({ path: '/admin/admin-manager' })
    }
  }
</script>

<style>
  #footer {
    height: 20px;
    padding-top: 2px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #F5F6F5;
    color: #282928;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
  #submit-button{
    text-align: center;
    margin-right:13% ;
    margin-top: 20px;
  }
  .tip{
    margin: 8px 0 5px 15px;
  }
  #select-button{
    margin-left: 16px;
  }
  .menu{
    padding-top: 10px;
  }
  .mt-8 {
    margin-bottom: 8px;
  }
  .mt-16 {
    margin-bottom: 16px;
  }
  .pd-16 {
    width: calc(100% - 32px);
    padding: 16px;
    background: #fff;
    margin-bottom: 8px;
  }
  .p8 {
    padding: 8px;
    cursor: pointer;
  }
</style>