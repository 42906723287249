<template>
  <div class="container">
    <div class="pd-16" id="reference-insert-btn">
      <el-row>
        <el-col :span="4">
          <el-select 
            v-model="queryParam.phaseId"
            filterable 
            placeholder="学年-学期-第几期" 
            @keyup.native.enter="refresh"
            @change="refresh"
          >
            <el-option
              v-for="item in phaseList"
              :key="item.id"
              :label="item.schoolYear + '-' + item.semester + '-' + item.phase"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="20" class="insert-btn-center">
          <el-tooltip :content="'导入' + templateName" placement="top">
            <a class="icon-btn" @click="toInsert">
              <i
                class="el-icon-upload2 p8"
                style="color: #409eff"
              />
            </a>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
    <div class="pd-16" style="height: 100%; overflow: auto">
      <!-- 表格 -->
      <el-table
        border
        :data="list"
        style="width: 100%"
        :height="tableHeight"
        class="mt-16"
      >
        <el-table-column prop="schoolYear" label="学年" min-width="180" />
        <el-table-column prop="semester" label="学期" min-width="180" />
        <el-table-column prop="phase" label="第几期" min-width="180" />
        <el-table-column prop="gradeName" label="年级" min-width="180" />
        <el-table-column prop="className" label="班级" min-width="180" />
        <el-table-column prop="student" label="学生名称" min-width="180" />
        <el-table-column prop="status" label="是否是团员" min-width="180">
          <template slot-scope="{ row }">
            <el-tag v-if="row.status === '是'" type="success">是</el-tag>
            <el-tag v-else type="warning">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="{ row }">
            <el-tooltip content="删除" placement="top">
              <el-popconfirm
                :title="'是否删除当前' + templateName + '信息？'"
                icon="el-icon-delete"
                icon-color="#F56C6C"
                placement="top-end"
                @confirm="remove(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-delete p8" style="color: #f56c6c" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row id="reference-page" class="mt-8" style="text-align: right">
        <el-pagination
          background
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :total="tTotal"
          :page-sizes="[15, 30, 60, 90]"
          small
          layout="total, sizes, prev, pager, next, jumper"
          class="fr"
          @size-change="
            (size) => {
              page.pageSize = size;
            }
          "
          @current-change="
            (num) => {
              page.pageNum = num;
            }
          "
        />
      </el-row>
    </div>
    <!-- 信息编辑弹出框 -->
    <el-dialog
      :visible.sync="editDialog"
      width="60vw"
      height="300px"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeDialog"
    >
      <span slot="title"> 导入{{ templateName }}信息 </span>
      <div style="width: 100%; background-color: aliceblue; border-left: 3px solid #409eff; margin-bottom: 16px; padding-left: 4px;">导入分配</div>
      <el-row>
        <el-select style="width: 1.875rem;" v-model="importParams.phaseId" filterable placeholder="学年-学期-第几期" @keyup.native.enter="refresh">
          <el-option
            v-for="item in phaseList"
            :key="item.id"
            :label="item.schoolYear + '-' + item.semester + '-' + item.phase"
            :value="item.id">
          </el-option>
        </el-select>
      </el-row>
      <el-row style="margin-top: 8px;">
        <el-upload
          class="upload-demo"
          ref="upload"
          drag
          action=""
          :file-list="importParams.fileList"
          :http-request="importReference"
          :auto-upload="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">只能上传.xls、.xlsx文件</div>
        </el-upload>
      </el-row>
      <el-row style="margin-top: 8px;">
        <el-button size="small" type="primary" @click="uploadFunction">导入</el-button>
      </el-row>
      <div style="width: 100%; background-color: aliceblue; border-left: 3px solid #409eff; margin: 16px 0px; padding-left: 4px;">手动分配</div>
      <ReferenceMatch ref="reference-match" />
    </el-dialog>
  </div>
</template>

<script>
import { list } from '@/api/shottr/phase'
import { importExcel } from '@/api/shottr/reference'
import { page, remove } from '@/api/shottr/base'
import ReferenceMatch from './components/ReferenceMatch'
export default {
  components: {
    ReferenceMatch
  },
  data() {
    return {
      queryParam: {
        phaseId: ''
      },
      importParams: {
        phaseId: '',
        fileList: []
      },
      templateName: '学生参学率',
      prefix: '/reference/',
      list: [],
      page: {
        pageNum: 1,
        pageSize: 15
      },
      tTotal: 0,
      editDialog: false,
      phaseList: [],
      tableHeight: 600
    }
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler(val) {
        this.queryData()
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.resetHeight()
    })
    this.queryPhases()
  },
  mounted() {
    window.onresize = () => {
      this.resetHeight()
    }
  },
  methods: {
    resetHeight() {
      const pageHeight = document.getElementById('reference-page').clientHeight
      const menuHeight = document.getElementById("menuId").clientHeight
      const insertBtnHeight = document.getElementById('reference-insert-btn').clientHeight
      this.tableHeight = window.innerHeight - menuHeight - insertBtnHeight - pageHeight - 100
    },
    queryPhases() {
      list().then(({ data }) => {
        this.phaseList = []
        if (data) {
          this.phaseList = data
        }
      })
    },
    refresh() {
      if (this.page.pageNum !== 1) {
        this.page.pageNum = 1
      } else {
        this.queryData()
      }
    },
    queryData() {
      const params = {
        ... this.page,
        ... this.queryParam
      }
      page(this.prefix, params).then(({ code, data, total }) => {
        this.tTotal = 0
        this.list = []
        if (code === 200 && data) {
          this.tTotal = total
          this.list = data
        }
      })
    },
    toInsert() {
      this.editDialog = true
    },
    closeDialog() {
      this.editDialog = false
      this.importParams = {
        phaseId: '',
        fileList: []
      }
    },
    uploadFunction() {
      this.$refs.upload.submit()
    },
    importReference(params) {
      if (!this.importParams.phaseId) {
        this.$message.warning('学年-学期-第几期不能为空！')
        return
      }
      if (!params.file) {
        this.$message.warning('导入Excel文件不能为空！')
        return
      }
      var file = params.file
      var fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      if (fileType !== 'xls' && fileType !== 'xlsx') {
        this.$message({
          message: '上传文件只能是xls或者xlsx文件！',
          type: 'warning'
        })
        this.importParams.fileList = []
        return
      }
      const isLt2M = (file.size / 1024 / 1024 < 20);
      if (!isLt2M) {
        this.$message({
          message: "文件大小不可以超过20M",
          type: 'warning'
        })
        this.importParams.fileList = []
        return
      }
      var formdata = new FormData()
      formdata.append('file', file)
      formdata.append('phaseId', this.importParams.phaseId)
      importExcel(formdata).then(({ data }) => {
        this.$message.success('成功导入' + data.successTotal + '条, 未匹配数据' + data.errorTotal + '条')
        this.$refs['reference-match'].refresh()
        this.closeDialog()
        this.refresh()
      })
    },
    remove(row) {
      remove(this.prefix, row.referenceId).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('删除成功')
          this.refresh()
        } else {
          this.$message.error('删除失败,' + message)
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  overflow: hidden;
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
