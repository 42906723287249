<template>
  <div class="login" v-loading="loading">
    <div class="container" id="container">
      <div class="form-container sign-up-container">
        <!-- 注册 -->
        <el-form>
          <h1>没有账号？</h1>
          <div class="message" style="text-align:center;display:block;">
            很抱歉，该系统目前暂不支持您主动注册，<br>
            仅供中医学院分团委学生会工作人员使用，<br>
            但您可通过以下几种方式联系管理员申请使用。</div>
          <span></span>
          <a class="help"
            href="mqqapi://card/show_pslcard?src_type=internal&version=1&uin=QQ账号&card_type=person&source=sharecard"
            target="_blank">
            <i class="iconfont icon-QQ"></i></span><span class="number">QQ账号</span></a>
          <a class="help" style="margin: 10px 0;">
            <i class="iconfont icon-weixin"></i></span><span class="number">微信号</span></a>
          <a class="help">
            <i class="iconfont icon-shouji"></i><span class="number">电话号码</span></a>
        </el-form>
      </div>
      <div class="form-container sing-in-container">
        <!-- 登陆 -->
        <el-form :model="ruleForm" :rules="loginRules" ref="ruleForm">
          <h1>中医学院分团委学生会办公系统</h1>
          <h1>用户登陆</h1>
          <div class="message">请输入用户名与密码</div>
          <el-form-item style="width: 100%;text-align: center;" prop="phone">
            <input v-model="ruleForm.phone" type="text" placeholder="用户名" />
          </el-form-item>
          <el-form-item style="width: 100%;text-align: center;" prop="password">
            <input v-model="ruleForm.password" type="password" placeholder="密码">
          </el-form-item>
          <a class="tips" style="text-align:center" @click="func()">忘记密码?</a>
          <button type="button" @click="loginCommit('ruleForm')">登 录</button>
        </el-form>
      </div>
      <!-- 侧边栏内容 -->
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-pannel overlay-left">
            <h1>已有账号?</h1>
            <p>返回登录页输入账号密码进入系统</p>
            <button class="ghost" id="signIn">Login</button>
          </div>
          <div class="overlay-pannel overlay-right">
            <h1>忘记帐号？</h1>
            <p>请点击下方按钮查看对应解决方式</p>
            <button class="ghost" id="signUp">help</button>
          </div>
        </div>
      </div>
    </div>
    <div class="beian">
      <center>
        <a class="text-color" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102005027"
          target="_blank">鄂公网安备42011102005027号</a>
        <a class="text-color" href="https://beian.miit.gov.cn" target="_blank">鄂ICP备2022017164号-1</a>
      </center>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        password: "",
        phone: ""
      },
      loginRules: {
        phone: [
          { required: true, message: "登录账号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "登录密码不能为空", trigger: "blur" },
        ],
      },
    }
  },
  mounted() {
    const container = document.querySelector('#container');
    const signInButton = document.querySelector('#signIn');
    const signUpButton = document.querySelector('#signUp');
    signUpButton.addEventListener('click', () => container.classList.add('right-panel-active'))
    signInButton.addEventListener('click', () => container.classList.remove('right-panel-active'))

  },
  methods: {
    loginCommit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const pData = JSON.parse(JSON.stringify(this.ruleForm))
          this.axios.post("/api/users/login", pData).then(({ data }) => {
            this.loading = false
            if (data && data.data) {
              localStorage.setItem("access_token", data.data)
              localStorage.setItem('userPhone', pData.phone)
              this.$message({
                type: "success",
                message: "登录成功"
              });
              this.$router.push({ name: "home" })
            } else {
              this.loading = false
              this.$message({
                type: "error",
                message: "账号密码错误，请重新登录"
              })
            }
          }).catch((err) => {
            this.loading = false
            this.$message({
              type: "error",
              message: err
            })
          })
        }
      })
    },
    func() {
      this.$message({
        type: "warning",
        message: "如您忘记账号或密码，请联系部门负责人或点击右侧help按钮查看帮助"
      })
    },
  }
}
</script>
<style scoped>
::v-deep .el-form-item__error {
  left: 100px !important;
}

@font-face {
  font-family: "iconfont";
  /* Project id  */
  src: url('~@/assets/iconfont.ttf?t=1676261512778') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shouji:before {
  content: "\3448";
}

.icon-weixin:before {
  content: "\f0106";
}

.icon-xinxi:before {
  content: "\e763";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-QQ:before {
  content: "\e882";
}
</style>
<style scoped>
* {
  margin: 0;
  padding: 0;
  /*标准盒子 */
  box-sizing: border-box;
}

.login {
  font-family: Arial, Helvetica, sans-serif;
  background: #f8f8f8;
  /* 锁定背景： */
  background-attachment: fixed;
  /* 弹性布局 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
}

h1 {
  margin: 19.2px 0;
  font-size: 24px;
}

p {
  font-size: 16px;
  line-height: 24px;
  /* 字体变淡 */
  font-weight: 100;
  margin: 19.2px 0;
  /* 字间距 */
  letter-spacing: 1.6px;
}

span {
  font-size: 16px;
  margin: 19.2px 0;
}

.container {
  /* 相对定位 */
  position: relative;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  padding: 9.6px;
  width: 980px;
  height: 560px;
  /* 溢出隐藏 */
  overflow: hidden;
  max-width: 100vw;
  min-height: 70vh;
}

.form-container form {
  background: #fff;
  /* 弹性布局 */
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.message {
  margin: 0 0 0 0;
  font-size: 15.2px;
  color: gray
}

.help {
  display: block;
  margin-left: auto 0;
  font-size: 32px;
  text-decoration: none;
  font-weight: 100;
  color: #333;
  height: 48px;
  width: 240px;
}

.number {
  display: inline-block;
  font-size: 16px;
  margin: 25.92px 32px;
  height: 48px;
  font-weight: 100;
}

.social-container {
  margin: 9.6px 0;
}

.social-container a {
  border: 1px solid #eee;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 28.8px;
  width: 28.8px;
}

.social-container a:hover {
  opacity: 0.8;
}

.form-container input {
  width: 65%;
  height: 35.2px;
  text-indent: 16px;
  border: 1px solid #ccc;
  /* 把input上左右边框取消 */
  border-left: none;
  border-right: none;
  border-top: none;
  /* 点击input边框消失 */
  outline: none;
  margin: 12.8px 0;
}

/* 被选中时候缩小 */
.form-container button:active {
  transform: scale(0.95, 0.95);
}

.form-container button {
  padding: 6.4px 16px;
  background: #417dff;
  color: white;
  border: 1px solid #fff;
  outline: none;
  /* 鼠标放上变小手 */
  cursor: pointer;
  width: 96px;
  border-radius: 8px;
  transition: all 100ms ease-in;
  margin: 9.6px 0;
  font-size: 15.2px;
  padding: 8px 0;
}

button#send_code {
  width: 100%;
}

.tips {
  background-color: white;
  color: #333;
  font-size: 15.2px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 100ms ease-in;
  margin: 9.6px 0;
  padding: 8px 0;
}

button.ghost {
  background: transparent;
  border-color: #fff;
  border: 1px solid #fff;
  outline: none;
  cursor: pointer;
  width: 80px;
  border-radius: 8px;
  transition: all 800ms ease-in;
  margin: 9.6px 0;
  padding: 8px 0;
  color: white;
  font-size: 16px;
}

button.ghost:active {
  transform: scale(0.95, 0.95);
}

.form-container {
  /* 绝对定位 */
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.5s ease-in;

}

.sing-in-container {
  left: 0;
  width: 60%;
  z-index: 2;
}

.sign-up-container {
  left: -40px;
  width: 60%;
  opacity: 0;
  z-index: 1;
}

.overlay {
  background: #417dff;
  width: 200%;
  height: 100%;
  position: relative;
  left: -100%;
  transition: all 0.6s ease-in-out;
  color: white;
}

.overlay-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  z-index: 99;
}

.overlay-pannel {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 0 35.2px;
}

.overlay-right {
  right: 0;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-150%);
}

.container.right-panel-active .sing-in-container {
  transform: translateX(50%);
}

.container.right-panel-active .sign-up-container {
  transform: translateX(75%);
  opacity: 1;
  z-index: 5;
  transition: all 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
  transition: all 0.6s ease-in-out;
}


@media screen and (max-width:720px) {
  .iconfont {
    font-size: 70px;
  }

  .el-form-item__error {
    left: 20px !important;
  }

  .login {
    font-family: Arial, Helvetica, sans-serif;
    background: #f8f8f8;
    /* 锁定背景： */
    background-attachment: fixed;
    /* 弹性布局 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 auto;
  }

  h1 {
    margin: 6.4px -2.4px;
    font-size: 100px;
  }

  p {
    font-size: 60px;
    line-height: 80px;
    /* 字体变淡 */
    font-weight: 100;
    margin: 80px -40px;
    /* 字间距 */
    letter-spacing: 3px;
  }

  span {
    font-size: 28px;
    margin: 40px 0;
  }

  .container {
    /* 相对定位 */
    position: relative;
    background: #fff;
    border-radius: 60px;
    box-shadow: 0 28px 36px rgba(0, 0, 0, 0.25), 0 20px 20px rgba(0, 0, 0, 0.22);
    padding: 20px;
    width: 100%;
    height: 1020px;
    /* 溢出隐藏 */
    overflow: hidden;
    max-width: 100vw;
    min-height: 70vh;
  }

  .form-container form {
    background: #fff;
    /* 弹性布局 */
    display: flex;
    flex-direction: column;
    padding: 0 56px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .message {
    margin: 40px 0;
    font-size: 70px;
    color: gray;
  }

  .help {
    display: block;
    margin-left: 0;
    font-size: 64px;
    text-decoration: none;
    font-weight: 100;
    color: #333;
    height: 96px;
    width: 480px;
  }

  .number {
    display: inline-block;
    font-size: 70px;
    margin: -92px 64px;
    height: 96px;
    font-weight: 500;
  }

  .social-container {
    margin: 20px 0;
  }

  .social-container a {
    border: 1px solid #eee;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    height: 56px;
    width: 56px;
  }

  .social-container a:hover {
    opacity: 0.8;
  }

  .form-container input {
    width: 100%;
    height: 160px;
    text-indent: 32px;
    border: 1px solid #ccc;
    /* 把input上左右边框取消 */
    border-left: none;
    border-right: none;
    border-top: none;
    /* 点击input边框消失 */
    outline: none;
    margin: 40px 0;
  }

  /* 被选中时候缩小 */
  .form-container button:active {
    transform: scale(0.95, 0.95);
  }

  .form-container button {
    padding: 12px 32px;
    background: #417dff;
    color: white;
    border: 1px solid #fff;
    outline: none;
    /* 鼠标放上变小手 */
    cursor: pointer;
    width: 320px;
    border-radius: 32px;
    transition: all 100ms ease-in;
    margin: 40px 0;
    font-size: 60px;
    padding: 32px 0;
  }

  button#send_code {
    width: 100%;
  }

  .tips {
    background-color: white;
    color: #333;
    font-size: 28px;
    cursor: pointer;
    width: 320px;
    border-radius: 16px;
    transition: all 100ms ease-in;
    margin: 40px 0;
    font-size: 60px;
    padding: 16px 0;
  }

  button.ghost {
    background: transparent;
    border-color: #fff;
    border: 1px solid #fff;
    outline: none;
    cursor: pointer;
    width: 320px;
    border-radius: 32px;
    transition: all 800ms ease-in;
    margin: 20px 0;
    padding: 32px 0;
    color: white;
    font-size: 60px;
  }

  button.ghost:active {
    transform: scale(0.95, 0.95);
  }

  .form-container {
    /* 绝对定位 */
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.5s ease-in;

  }

  .sing-in-container {
    left: 0;
    width: 60%;
    z-index: 2;
  }

  .right-panel-active .sing-in-container {
    left: 100px;
  }

  .sign-up-container {
    left: -3px;
    width: 60%;
    opacity: 0;
    z-index: 1;
  }

  .overlay {
    background: #417dff;
    width: 200%;
    height: 100%;
    position: relative;
    left: -100%;
    transition: all 0.6s ease-in-out;
    color: white;
  }

  .overlay-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    z-index: 99;
  }

  .overlay-pannel {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    padding: 0 70px;
  }

  .overlay-right {
    right: 0;
  }

  .container.right-panel-active .overlay-container {
    transform: translateX(-150%);
  }

  .container.right-panel-active .sing-in-container {
    transform: translateX(60%);
  }

  .container.right-panel-active .sign-up-container {
    transform: translateX(70%);
    opacity: 1;
    z-index: 5;
    transition: all 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    transform: translateX(50%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateX(0%);
    transition: all 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay-right {
    transform: translateX(20%);
    transition: all 0.6 ease-in-out;
  }

  .beian {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 4px;
    font-size: 28px;
  }

  .text-color {
    margin-left: 16px;
    color: #9e9e9e;
  }
}

.beian {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 4px;
}

.text-color {
  margin-left: 16px;
  color: #9e9e9e;
}
</style>
<!-- <template>
  <div class="main-content">
    <div class="title">中医临床学院学生会办公系统</div>
    <div class="form">
      <div class="login-font">账号登录</div>
      <div class="target"></div>
      <div class="target-line"></div>
      <el-form
        :model="ruleForm"
        :rules="loginRules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="phone">
          <el-input
            id="phone"
            v-model="ruleForm.phone"
            prefix-icon="el-icon-user"
            placeholder="手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            prefix-icon="el-icon-user"
            placeholder="登录密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn-group">
            <div>
              <el-button
                :loading="loading"
                type="primary"
                @click="loginCommit('ruleForm')"
                style="
                  width: 100%;
                  background: rgb(49, 115, 204);
                  border-color: rgb(49, 115, 204);
                "
              >
                {{ loading == false ? "登 录" : "登录中..." }}
              </el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="beian">
      <center>
        <a class="text-color" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102005027" target="_blank">鄂公网安备42011102005027号</a>
        <a class="text-color" href="https://beian.miit.gov.cn" target="_blank">鄂ICP备2022017164号-1</a>
      </center>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        password: "",
        phone: ""
      },
      loginRules: {
        phone: [
          { required: true, message: "登录账号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "登录密码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.enterLogin()
  },
  mounted () {
    document.getElementById('phone').focus()
  },
  methods: {
    //提交登录
    loginCommit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const pData = JSON.parse(JSON.stringify(this.ruleForm))
          this.axios.post("/api/users/login", pData).then(({ data }) => {
            this.loading = false
            if (data && data.data) {
              localStorage.setItem("access_token", data.data)
              localStorage.setItem('userPhone', pData.phone)
              this.$message({
                type: "success",
                message: "登录成功"
              });
              this.$router.push({ name: "home" })
            } else {
              this.loading = false
              this.$message({
                type: "error",
                message: "账号密码错误，请重新登录"
              })
            }
          })
        }
      })
    },
    enterLogin(){
      document.onkeydown =(e) => {
        e = window.event || e
        if(this.$route.path === '/login' && (e.code === 'Enter' || e.code === 'enter')){
          //调用登录事件方法
          this.loginCommit('ruleForm');
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/main.jpg");
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
}

.main-content .title {
  color: white;
  font-size: 30px;
  font-weight: 500;
  position: absolute;
  top: 58px;
  left: 135px;
}

.main-content .form {
  width: 300px;
  background: white;
  position: absolute;
  top: 20%;
  left: 68%;
  border-radius: 8px;
  box-shadow: 5px 5px 20px 0px #606266;
  padding: 36px 52px;
}

::v-deep .el-input__inner {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.target {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 4px solid #3173cc;
}

.target-line {
  border-bottom: 2px solid #e4e4e4;
  margin-bottom: 18px;
}

.login-font {
  width: 100%;
  font-size: 18px;
  color: rgb(49, 115, 204);
  margin-bottom: 16px;
  text-align: center;
}

.btn-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btn-group div {
  width: 100%;
}

.beian {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 4px;
}
.text-color {
  margin-left: 16px;
  color: #9e9e9e;
}
</style> -->
